// Remove gray background from mat-button when hovered
.mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: transparent;
}

// MDC icon buttons try to set the size of icons within them
// This unsets it for terra-icons so that the input size is used
.mat-mdc-icon-button terra-icon svg {
  width: unset;
  height: unset;
}
