@use 'terra';
@import './shared';

.partner-hub-vto-tabs {
  .right-toolbar-tab {
    justify-content: end;
  }

  mat-tab-body {
    padding: 32px;
  }
}

.partner-hub-config-tabs {
  .mat-tab-label {
    min-width: 0;
    padding: 0 20px;
    &:last-child {
      margin-left: auto;
    }
  }
  .vto-tab-group {
    .mat-tab-label:last-child {
      margin-left: unset;
    }
  }
  .config-right-tab.mat-mdc-tab.mat-mdc-tab-disabled {
    opacity: unset;
    .mdc-tab__content {
      pointer-events: all;
    }
  }

  .conversation-settings-tabs {
    .mat-tab-label {
      &:last-child {
        margin-left: unset;
      }
    }
  }
}

.template-options-menu.mat-menu-panel {
  width: 200px;
}

.partner-hub-side-nav {
  .mat-expansion-panel-header {
    padding: 0.25em 1em !important; // have to use to override, look at fixing the specificity of the mat-expansion-panel-header
  }
}

.referred-companies-search.mat-form-field-appearance-outline {
  flex: 3;
  // temp overrides before mdc is updated next sprint
  .mat-form-field-flex {
    padding-right: 0;
  }
  .mat-form-field-suffix {
    top: -2px;
  }
}

/** utility class */
ninety-button.pill {
  button {
    border-radius: terra.radius(huge) !important;
  }
}

.insights-menu button {
  ninety-icon {
    margin-right: 8px;
  }
}

.template-one-on-one-settings {
  .mat-mdc-card-content:first-child {
    padding-top: 0;
  }

  // temporary until mdc is updated
  .mat-tab-group {
    padding: 16px;
  }
}

.app-nav-accordion {
  .mat-expansion-panel-header {
    @include terra.text(body-large);
  }

  .count-badge {
    @include terra.text(body);
    margin-left: 8px;
  }
}

.share-template-table {
  .mat-column-un-share {
    width: 60px;
  }
}
