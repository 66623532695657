@import 'media-queries';

/// Applies standard sizing for grid pages
@mixin set-default-grid-page-padding($desired-sm-padding: 16px, $desired-lg-padding: 24px, $grid-inset: 8px) {
  padding: $desired-sm-padding - $grid-inset;

  @include screen-gt(1000px) {
    padding: $desired-lg-padding - $grid-inset;
  }
}
