// Overrides for Mat Form Field used inside of the Intl Tel Input component
ninety-intl-tel-input {
  mat-form-field {
    .mat-mdc-form-field-wrapper {
      padding-bottom: 0 !important;
      margin: 0 !important;
      width: 100% !important;
    }

    .mat-mdc-text-field-wrapper {
      height: 38px !important;
    }
  }
}
