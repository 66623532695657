@use 'terra';
/// This is something that's used only the internal partners page right now,
// but could be used elsewhere if someone wanted.
.suffix-group {
  display: flex;
  align-items: center;
  & > * {
    margin: 0;
  }
  button {
    @include terra.radius(none);
    height: 40px !important;
  }
}

// A class to wrap mat-form-field and button together that will make the button look attached to the input.
.input-button-combo {
  display: flex;
  align-items: center;
  flex: 1;
  ninety-button {
    margin: 0;
    button {
      // have to use important here, normal specificity is 0,3,0.
      border-radius: terra.radius(none) terra.radius(small) terra.radius(small) terra.radius(none) !important;
      height: 40px !important;
    }
  }
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
