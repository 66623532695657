@use 'terra';

// Fixes the cells & divider line styles on drag/drop
.scorecard-list-row {
  &.cdk-drag-preview {
    & > td {
      height: 48px;

      &.mat-cell:first-of-type {
        padding-left: 0;
      }

      &.td-title > div {
        margin: 0 16px;
        height: 100%;
      }

      &:not(.td-score) {
        padding-top: 10px;
      }
    }
  }
}

ninety-scorecard {
  .mat-mdc-select-value {
    @include terra.text(body);
  }

  .mat-datepicker-toggle {
    .mat-mdc-icon-button {
      padding: 0;
    }
  }
}
