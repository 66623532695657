@use 'terra';

/**
 * Override needed where we have a mat-form-field and need a terra-form-label before it
 *
 *  Apply this class to a parent div of the mat-form-field and terra-form-label
 *
 */
._terra-migration-approved-override--terra-mat-form-field {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

/**
  * Optionally (pun) if an optional text is needed on the terra-form-label in a terra-form-field for the above situation
  * You can apply this class to a span in the terra-form-label
  */
._terra-migration-approved-override--terra-form-optional {
  color: terra.color(text, lightest);
  @include terra.text(body);
  display: inline-block !important;
}

/*
* Used when we have a form that has a count of characters remaining
* and can't use the terra-form-field yet
*
*/
._terra-migration-approved-override--count-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

/*
* Override for the mat-form-field component to match Terra inputs, useful
* until we have the needed Terra input type (like date)
*
* This assumes that the form field has `appearance="outline"` set
*/
mat-form-field._terra-migration-approved-override--mat-form-field {
  &.mat-form-field {
    all: unset !important;
    display: block !important;

    *:not(mat-datepicker-toggle, mat-datepicker-toggle *) {
      all: unset !important;
    }

    .mat-form-field-wrapper {
      display: flex !important;
      background: terra.color(white) !important;
      height: 34px !important;
      border-radius: terra.radius(small) !important;
      @include terra.text(body, true);
      border: 1px solid terra.color(border) !important;
      padding-inline: 16px !important;
      min-width: fit-content !important;
      color: terra.color(text) !important;
      align-items: center !important;
    }

    .mat-form-field-flex {
      height: 20px !important;
      display: flex !important;
      width: 100% !important;
    }

    .mat-form-field-infix {
      flex: 1 !important;
    }

    .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
      opacity: 0 !important;
    }

    &:hover:not(.mat-form-field-disabled):not(.mat-form-field-invalid) .mat-form-field-wrapper {
      cursor: pointer !important;
      border-color: terra.color(border, heavy) !important;
    }

    &.mat-form-field-disabled .mat-form-field-wrapper {
      background: terra.color(neutral-light, 400) !important;
      color: terra.color(text, disabled) !important;
    }

    input {
      border: none !important;
      color: inherit !important;
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
      overflow: hidden !important;
      background: transparent !important;
      width: 100% !important;
      padding: 0 !important;

      &:focus-within {
        outline: none !important;
      }
    }

    &:has(input:focus-visible) .mat-form-field-wrapper {
      outline: 4px solid terra.color('brand', 'shader-heavy') !important;
      border: 1px solid terra.color('brand') !important;
    }

    &.mat-form-field-invalid {
      .mat-form-field-wrapper {
        border-color: terra.color('red') !important;
      }

      &:has(input:focus-visible) .mat-form-field-wrapper {
        outline: 4px solid terra.color('red', 'shader-heavy') !important;
      }
    }

    .mat-datepicker-toggle {
      position: relative;
      bottom: 8px;
      left: 16px;
    }

    .mat-datepicker-toggle .mat-mdc-button-base {
      padding-block: 6px;
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .mat-mdc-form-field-infix {
    min-height: 36px !important;
    padding-block: 6px !important;
  }

  // Overrides for MDC form fields
  .mdc-text-field--outlined {
    .mat-mdc-form-field-icon-prefix,
    [dir='rtl'] .mat-mdc-form-field-icon-suffix {
      > span {
        padding-inline-start: 8px;
      }
    }

    .mat-mdc-form-field-icon-suffix,
    [dir='rtl'] .mat-mdc-form-field-icon-prefix {
      > span {
        padding-inline-end: 8px;
      }
    }

    .mat-datepicker-toggle .mat-mdc-button-base {
      padding-block: 6px;
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:not(.mdc-text-field--focused, .mdc-text-field--disabled)
      .mat-mdc-form-field-flex:hover
      .mdc-notched-outline__leading,
    &:not(.mdc-text-field--focused, .mdc-text-field--disabled)
      .mat-mdc-form-field-flex:hover
      .mdc-notched-outline__notch,
    &:not(.mdc-text-field--focused, .mdc-text-field--disabled)
      .mat-mdc-form-field-flex:hover
      .mdc-notched-outline__trailing {
      border-color: terra.color(border, heavy) !important;
    }

    :focus-within .mdc-notched-outline,
    :focus-within .mdc-notched-outline__leading,
    :focus-within .mdc-notched-outline__trailing {
      border-color: terra.color('brand') !important;
      border-width: 1px !important;
    }

    :focus-within .mdc-notched-outline {
      outline: 4px solid terra.color('brand', 'shader-heavy') !important;
      @include terra.radius(small);
    }
  }
}

/*
* Override for the mat-form-field component to match Terra Text Area
*
* This assumes that the form field has `appearance="outline"` set
*/

._terra-migration-approved-override--mat-text-area {
  &:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline {
    color: terra.color(border, heavy) !important;
  }

  textarea {
    max-height: unset !important;
  }

  .mat-form-field-wrapper {
    margin: 0;
    padding: 0;
  }

  .mat-form-field-infix {
    padding: 4px 4px 8px !important;
  }

  &.mat-focused {
    .mat-form-field-outline {
      color: 1px solid terra.color('brand') !important;
      outline: 4px solid terra.color('brand', 'shader-heavy') !important;
      @include terra.radius(small);
    }

    .mat-form-field-flex:hover .mat-form-field-outline {
      color: terra.color('brand') !important;
    }

    .mat-form-field-flex .mat-form-field-outline {
      color: terra.color('brand') !important;
    }
  }

  .mdc-text-field {
    background: terra.color(white);
    @include terra.radius(small);
  }

  .mat-form-field-outline-end,
  .mat-form-field-outline-start {
    border-width: 1px !important;
  }

  /*
  * Override MDC versions for text area
  *
  * This assumes that the form field has `appearance="outline"` set
  */

  .mat-mdc-form-field-infix {
    min-height: 36px !important;
    padding-block: 5px !important;
  }

  .mdc-text-field--outlined {
    &:not(.mdc-text-field--focused, .mdc-text-field--disabled)
      .mat-mdc-form-field-flex:hover
      .mdc-notched-outline__leading,
    &:not(.mdc-text-field--focused, .mdc-text-field--disabled)
      .mat-mdc-form-field-flex:hover
      .mdc-notched-outline__notch,
    &:not(.mdc-text-field--focused, .mdc-text-field--disabled)
      .mat-mdc-form-field-flex:hover
      .mdc-notched-outline__trailing {
      border-color: terra.color(border, heavy) !important;
    }

    :focus-within .mdc-notched-outline,
    :focus-within .mdc-notched-outline__leading,
    :focus-within .mdc-notched-outline__trailing {
      border-color: terra.color('brand') !important;
      border-width: 1px !important;
    }

    :focus-within .mdc-notched-outline {
      outline: 4px solid terra.color('brand', 'shader-heavy') !important;
      @include terra.radius(small);
    }
  }
}
