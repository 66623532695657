ninety-select,
ninety-select-yes-no-maybe {
  mat-form-field {
    padding-bottom: 19px !important;
    box-sizing: content-box;
  }

  .mat-mdc-text-field-wrapper {
    padding-left: 10px;
    padding-right: 12px;
  }

  .mdc-text-field--outlined {
    // these three border-color definitions could be replaced with --mdc-outlined-text-field-outline-color, but our color variables don't work with that
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: terra.color('border') !important;
    }

    &:hover {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: terra.color('border', 'heavy') !important;
      }
    }
  }
}

.role-select-panel {
  min-width: 260px;
  position: absolute;
  top: 100% !important;
  max-height: 100px;
  overflow-y: auto;
  overflow-x: hidden !important;

  @media (min-height: 600px) and (min-width: 860px) {
    min-height: 425px;
    top: 100% !important;
  }
}

.mat-mdc-option {
  min-height: 42px !important;
  .mdc-list-item__primary-text {
    overflow: initial !important;
  }
}

ninety-select.hide-border {
  .mdc-notched-outline {
    display: none;
  }
}

ninety-select.short-select {
  mat-form-field {
    height: 36px;
    max-height: 36px;
  }

  .mat-mdc-form-field-infix {
    min-height: 36px !important;
    height: 36px !important;
  }

  mat-select {
    position: relative;
    top: -7px;
  }
}

ninety-select.border-label-select span {
  padding-left: 3px;
}
