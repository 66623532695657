@use 'terra';
.mdc-context-menu .mat-mdc-menu-item {
  &:not(.red) mat-icon {
    color: terra.color('text', 'light') !important;
  }

  .mat-mdc-menu-item-text {
    display: flex;
    align-items: flex-start;
    .mat-icon {
      vertical-align: middle;
      svg {
        vertical-align: top;
      }
    }
    & > *:not(terra-icon, terra-icon + span) {
      @include terra.text(body);
      line-height: 48px; //TERRATODO this is legacy but helps align some icons to menu-item text, it looks real bad if text has to wrap
    }
  }
}
