@use 'terra';

.scorecard-action-bar-menu,
.kpi-group-action-menu,
.kpi-detail-action-menu {
  .mat-mdc-menu-item-text {
    display: flex;
    justify-content: center;

    terra-icon {
      padding-top: 1px;
    }
  }
}

// Prevents horizonal overflow for 'New group' button in the action bar, wraps under filters
.scorecard-action-bar {
  .action-bar {
    flex-wrap: wrap;
    gap: 8px;
  }
}

// Hide quick filter label and separator for small viewports
@media (max-width: 794px) {
  ninety-scorecard-team-select,
  .scorecard-period-interval-select {
    .pill-inner {
      .pill-title,
      .pill-separator {
        display: none;
      }
    }
  }
}

ninety-kpi-group-card {
  .ag-header-select-all {
    // This aligns check-all box above row checkboxes, which are offset by their drag-handle
    margin-left: 18px;
  }
}

.kpi-detail-action-menu {
  terra-icon {
    color: terra.color('text', 'lightest');
  }
}

.ag-theme-ninety {
  .ag-cell {
    &.kpi-owner {
      justify-content: center;
    }
  }
}

// Fix transparent background when dragging KPI groups in detail
.mat-mdc-list-item.reorder-kpi-group-item {
  background-color: terra.color('white');
}

// DEV-14985 fixes Windows Edge issue with menu height exceeding viewport and not scrolling
.select-seat-then-user-seats-menu {
  max-height: 500px;
}
