@use '../core/colors/colors';

/*
* Override for the filters-toolbar.component.html until it is updated to the new design.
* Without override, the switch blends in with the background of the toolbar.
*/
._terra-migration-approved-override--filters-toolbar {
  .terra-switch__switch {
    background-color: colors.color('brand', 'heaviest') !important;
    &:after {
      background-color: colors.color('text-invert', 'light') !important;
    }
  }

  .terra-switch__input {
    &:checked ~ .terra-switch__switch {
      &:after {
        background-color: colors.color('text-invert') !important;
      }
    }
  }
}
