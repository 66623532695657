// NO CSS is generated/output directly in this file
@use '../utility/strings.mixins' as strings;
@use 'sass:list';
@use 'sass:string';

$_storybook-prefix: 'storybook-root';

///
/// For use with Angular components to force pseudo states that are protected by the browser.
/// It will add a pseudo-<states> selector that can be used on the component host element
/// It can only be used within Storybook stories
///
/// @param $states - list of strings that will be appended to the pseudo- selector. They can be anything but it's recommended to name them the same as actual pseudo states
///
/// @content passes content to the new selector
///
/// @example scss
///   .component{
///     &:hover {
///      @include pseudo-state('hover') {
///        color: red;
///      }
///     }
///    }
///
/// @example html
///   <component class="pseudo-hover"></component>
///
@mixin pseudo-state($states...) {
  $selector: #{&};
  $selectors: string.split($selector, ',');

  @each $selectorChunk in $selectors {
    $pseudo-selector: '.pseudo';
    $selector: $selectorChunk;

    @each $state in $states {
      $pseudo-selector: '#{$pseudo-selector}-#{$state}';
      $selector: #{strings.remove-from-string($selector, ':#{$state}')};
    }
    // @eslint-context-ng-deep Needed to break free from encapsulation or else the storybook root will have encapsulation selectors on it
    @at-root ::ng-deep .sb-show-main #{$pseudo-selector} #{$selector} {
      @content;
    }
  }

  @content;
}

///
/// For use with simple selectors to force pseudo states that are protected by the browser.
/// It will add a pseudo-<states> selector that can be used on the element
/// It can only be used within Storybook stories
///
/// @content passes content to the new selector
///
/// @param $states - list of strings that will be appended to the pseudo- selector. They can be anything but it's recommended to name them the same as actual pseudo states.
///
/// @example scss
///   a{
///     &:hover {
///      @include pseudo-selector('hover') {
///        color: red;
///      }
///     }
///    }
///
/// @example html
///   <a class="pseudo-hover"></a>
///
@mixin pseudo-selector($states...) {
  $selector: #{&};
  $pseudo-selector: '.pseudo';
  @each $state in $states {
    $pseudo-selector: '#{$pseudo-selector}-#{$state}';
    $selector: #{strings.remove-from-string($selector, ':#{$state}')};
  }
  @at-root #{$_storybook-prefix} #{$selector}#{$pseudo-selector} {
    @content;
  }

  @content;
}
