@use 'sass:map';
@use '../colors/colors';

$_terra-elevations: (
  '0': (
    'down': none,
  ),
  '1': (
    'down': 0px 1px 1px 1px colors.color('shadow', 'lightest'),
  ),
  '2': (
    'down': 0px 1px 2px 1px colors.color('shadow', 'light'),
    'right': 1px 0px 2px 1px colors.color('shadow', 'light'),
    'left': -1px 0px 2px 1px colors.color('shadow', 'light'),
  ),
  '3': (
    'down': 0px 2px 4px 1px colors.color('shadow', 'mid'),
  ),
  '4': (
    'down': 0px 4px 8px 1px colors.color('shadow', 'heavy'),
    'left': -4px 0px 8px 1px colors.color('shadow', 'heavy'),
  ),
  '5': (
    'down': 0px 6px 10px 1px colors.color('shadow', 'heaviest'),
  ),
);

///
/// @param {type} $mode - 'light' | 'dark' - Indicates what shadow color is used
/// @param {type} $background-color - The Terra color used for the scroll container's background-color
/// @param {type} $edge ['both'] - 'both' | 'top' | 'bottom' - Indicates which edge(s) to apply the shadow
/// @output - Scroll container with a Terra scroll-shadow effect
/// @example
/// terra.scroll-shadow('light', terra.color('neutral-light', '200');
/// terra.scroll-shadow('dark', terra.color('neutral-mid', '400');
/// terra.scroll-shadow('light', terra.color('neutral-light', '200', 'top');
///
@mixin scroll-shadow($mode, $background-color, $edge: 'both') {
  // Convert params to string for consistency
  $mode: #{$mode};
  $background-color: #{$background-color};
  $edge: #{$edge};

  $shadow-color: null;
  @if $mode == 'light' {
    $shadow-color: colors.color('shadow', 'lightest');
  } @else if $mode == 'dark' {
    $shadow-color: colors.color('shadow', 'heaviest');
  } @else {
    @error 'Expected `light` or `dark` for scroll-shadow $mode but was given: #{$mode}';
  }

  $top-background: linear-gradient($background-color 30%, rgba(255, 255, 255, 0));
  $top-shadow: linear-gradient($shadow-color 30%, rgba(255, 255, 255, 0));
  $bottom-background: linear-gradient(rgba(255, 255, 255, 0), $background-color 70%) 0 100%;
  $bottom-shadow: linear-gradient(rgba(255, 255, 255, 0), $shadow-color 70%) 0 100%;

  $background-size: 100% 15px;
  $shadow-size: 100% 5px;

  $background-attachment: local;
  $shadow-attachment: scroll;

  @if $edge == 'both' {
    background: $top-background, $bottom-background, $top-shadow, $bottom-shadow;
    background-size: $background-size, $background-size, $shadow-size, $shadow-size;
    background-attachment: $background-attachment, $background-attachment, $shadow-attachment, $shadow-attachment;
  } @else {
    @if $edge == 'top' {
      background: $top-background, $top-shadow;
    } @else if $edge == 'bottom' {
      background: $bottom-background, $bottom-shadow;
    } @else {
      @error 'Value for scroll-shadow edge was not expected: #{$edge}';
    }
    background-size: $background-size, $shadow-size;
    background-attachment: $background-attachment, $shadow-attachment;
  }

  background-repeat: no-repeat;
  background-color: $background-color;
}

///
/// @param {type} $elevation - elevation number 0-5 from Figma
/// @param {type} $direction - down by default; e.g. right|left|up (if applicable)
/// @output - Terra box-shadow style
/// @example
/// @include terra.elevation(5)
/// @include terra.elevation(2, 'right')
@mixin elevation($elevation, $direction: 'down') {
  box-shadow: elevation($elevation, $direction);
}

///
/// Function to retrieve an official Terra-approved elevation value
/// @param {string} $elevation - elevation number 0-5 from Figma
/// @param {string} $direction - down by default; e.g. right|left|up (if applicable)
/// @output - elevation values to create the desired box-shadow
///
@function elevation($elevation, $direction: 'down') {
  // Convert params to string for consistency
  $elevation: #{$elevation};
  $direction: #{$direction};

  @if map.has-key($_terra-elevations, $elevation, $direction) {
    @return map.get($_terra-elevations, $elevation, $direction);
  } @else {
    @error 'Elevation style does NOT exist: #{$elevation} #{$direction}';
  }
}
