@use 'terra';

.language-cards {
  .mat-mdc-card {
    padding: 20px 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .mat-mdc-card-header {
      padding: 0;
    }
    .mat-mdc-card-title {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .mat-mdc-card-content {
      @include terra.text(body);
      padding: 0;
      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  &.readonly {
    ninety-reset-btn {
      display: none;
    }
  }

  .inputs-div {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    .mat-mdc-form-field,
    terra-form-field {
      width: 240px;
    }
  }

  terra-icon {
    color: terra.color('text', 'light');
  }
}
