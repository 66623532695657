ninety-text-editor-froala {
  .fr-box {
    // quick-fix to prevent text-editor from expanding horizontally
    // as the user types
    // Reference: https://stackoverflow.com/a/61212563/12734092
    width: 0px;
    min-width: calc(100% - 2px); // Subtract border pixels
    max-width: calc(100% - 2px);
  }

  // Override toolbar button group margins to prevent Froala from adding margin
  // based on viewport (the editor now responds to element width, not viewport)
  #froala-xs .fr-toolbar .fr-btn-grp,
  #froala-sm .fr-toolbar .fr-btn-grp {
    margin: 0 5px 0 5px;
  }

  #froala-md .fr-toolbar .fr-btn-grp,
  #froala-lg .fr-toolbar .fr-btn-grp {
    margin: 0 8px 0 8px;
  }
}
