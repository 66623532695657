// Global module barrel file

// NO CSS should be generated from this entire module

/*
 * Export point for various mixins
 *
 * If a mixin needs namespacing it should be added here
 *
 * This is where show/hide should be used from forwarded partials so that only things that should be public are
 *
 * This barrel should only forward the partials
 *
 * Everything that is forwarded as public needs to be documented somewhere so that developers know that it exists and what it does
 */

// Example
// @forward '../core/color/colors' show color; // Only forwards the color method, all other members are now private
// @forward '../core/typography/typography' show text, textFoo; // Only forwards the text and textFoo method, all other members are now private
// @forward '../core/a11y/a11y'; // Forwards all members as all are allowed to be public

@forward '../core/colors/colors' show color;

@forward '../core/colors/palettes' show $legacy-company-palette, $legacy-gray-palette;

@forward '../core/typography/typography' show text, legacy-get-nunito;

@forward '../core/radius/radius' show radius;

@forward '../core/elevation/elevation' show elevation, scroll-shadow;

@forward '../utility/pseudo.mixins' show pseudo-selector, pseudo-state;

@forward '../core/a11y/a11y.mixins' show a11y-visually-hidden;
