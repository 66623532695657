@use 'terra';

// temporary - can be deleted when the below 5px margin is removed
.ninety-page-container {
  .mat-mdc-card {
    margin: 0;
  }
}

.mat-mdc-card {
  // temporary - to keep some of the same spacing and prevent less impact (when not following design system)
  margin: 5px;

  box-shadow: terra.elevation(3) !important;

  .mat-mdc-card-header {
    .mat-mdc-card-title {
      @include terra.text(title);
    }

    // borrowed from ninety-card until it can be extended enough for a swap
    .card-title-count {
      background-color: terra.color('neutral-shader');
      @include terra.radius(small);
      @include terra.text(body-large);

      padding: 4px;
      margin-top: 2px;
      margin-left: 8px;
    }
  }

  .mat-mdc-card-content {
    @include terra.text(body);
  }
}

// LEGACY CARD STYLES - to be deleted after transition is complete
// NOTE - These styles can have MASSIVE effects so be extremely careful when modifying
// use classes to scope to only legacy components (.mat-card instead of .mat-mdc-card)
.mat-card {
  margin: 5px;
  padding: 16px;

  // Note - Keep element selector because there are specificity overrides in other components using the class,
  // however it's still scoped to legacy component because of parent class selector
  mat-card-header {
    padding: 16px 0 0;
  }

  // Note - Keep element selector because there are specificity overrides in other components using the class,
  // however it's still scoped to legacy component because of parent class selector
  mat-card-title {
    @include terra.text(body-large);
  }
}

// TODO(mdc-migration) - Remove after migration
// yes....this is a duplicate of the above, but there were some overrides that needed to be kept,
// e.g. accountability chart seat card
.mat-card-header .mat-card-title {
  @include terra.text(body-large);
}
