@use '@angular/material' as mat;
@use 'terra';

ninety-rock-status-menu-v2 {
  button.mat-mdc-icon-button {
    &.readonly {
      opacity: unset;
    }
    terra-icon {
      &.footprints {
        svg {
          transform: scale(65%);
        }
      }

      &.warning-triangle {
        svg {
          transform: scale(65%);
        }
      }
    }
  }
}

.rock-status-menu-v2__menu-button.menu-icon-button {
  terra-icon {
    &.footprints {
      svg {
        transform: scale(65%);
      }
    }

    &.warning-triangle {
      svg {
        transform: scale(65%);
      }
    }
  }
  span.mat-mdc-menu-item-text {
    justify-content: space-between !important;
    align-items: center !important;
    width: 100% !important;
  }
}
