// Remove gray background from mat-select
.mat-mdc-form-field-type-mat-select {
  --mdc-filled-text-field-container-color: transparent;
}

.issue-rating__other-team {
  .mdc-text-field {
    padding: 0 !important;
  }
}
