/* Global Styles applied to any element with `NinetyInputDirective` */
@use 'terra';
@import 'mixins';
@import './inputs-mixins';

// Any Input
input.ninety-input {
  width: 100%;
  box-sizing: border-box; // Include border and padding in the elements width/height

  @include ellipsis();

  @include set-input-padding(); // Set defaults, override with more specific selector
  @include input-border(); // When used alone, add border to the native input
}

ninety-input-wrapper {
  // Globally style all icons - prime candidate for ng-deep
  ninety-icon,
  terra-icon {
    color: terra.color('text', 'lightest');
  }

  // When used in a wrapper, strip the styles from the native input
  input.ninety-input {
    @include strip-input-border();
    @include set-input-padding(0, 0);
  }

  [ninetyInputPrefix] + input.ninety-input {
    padding-left: 8px;
  }

  ninety-button[ninetyInputPrefix] + input.ninety-input {
    padding-left: 0;
  }

  input.ninety-input + [ninetyInputSuffix] {
    padding-left: 8px;
  }

  input.ninety-input + ninety-button[ninetyInputSuffix] {
    padding-left: 0;
  }
}
