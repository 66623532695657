@mixin linear-spin($timing: 1s) {
  animation: spin $timing linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin-indefinitely-1s {
  @include linear-spin;
}
