@use 'terra';

ninety-button {
  &:not([icon]) {
    $gutter: 8px;

    // Temp class until we have a true ninety menu item component
    ninety-icon,
    terra-icon {
      &.mat-menu-icon {
        margin-right: $gutter;
        margin-left: 0;
        color: terra.color('text', 'lightest');
      }
    }

    terra-icon.mat-menu-icon {
      vertical-align: middle;
    }
  }
}
