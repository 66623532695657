@use '../utility/pseudo.mixins' as pseudo;
@use '../core/colors/colors' as terra-colors;
@use '../core/typography/typography' as terra-typography;
/**
    Firma

    This file contains code that normalizes the inconsistencies between different browsers and sets base styles

    These selectors are meant to be the lowest specificity possible and to sit just above browser User Agent Styles
    It has minimal Terra mixins and variables.

    Mainly based off of sanitize.css (https://csstools.github.io/sanitize.css/)

    Any additions in here are REQUIRED to have a direct comment explaining why it's there and if it's opinionated
 */

/**
1. Use a better box model (opinionated).
TERRATODO large changes possible, needs more assessment of impact
*/
:where(*, *::before, *::after) {
  box-sizing: border-box; /* 1 */
}

/**
  1. Set the default font size  (Terra opinionated).
*/
:where(:root) {
  font-size: terra-typography.get-root-text-size(); /* 1 */
}

/**
1. Prevent adjustments of font size after orientation changes in iOS. (opinionated)
2. Remove the grey highlight on links in iOS (opinionated)
3. Remove the margin & padding in all browsers. (opinionated)
*/
:where(html) {
  -webkit-text-size-adjust: 100%; /* 1 */
  text-size-adjust: 100%; /* 1 */
  -webkit-tap-highlight-color: transparent; /* 2 */
  margin: 0; /* 3 */
  padding: 0; /* 3 */
}

/**
 1. Set the default font attributes to Terra defaults (Terra opinionated)
 2. Set the default text color for the app (Terra opinionated)
 3. Set the default app background color (Terra opinionated)
 4. Remove the margin in all browsers. (opinionated)
 5. Set an explicit initial text-align value so that we can later use the `inherit` value on things like `<th>` elements.
 6. Set the height of body to 100vh to prevent collapsing (Terra opinionated)
 7. Prevent overflow of body (Terra legacy opinionated)
 8. Enable smooth scrolling (Terra opinionated)
 */
:where(body) {
  @include terra-typography.get-default-text-style(); /* 1 */
  color: terra-colors.color(text); /* 2 */
  background-color: terra-colors.color('neutral-light', 200); /* 3 */
  margin: 0; /* 4 */
  padding: 0; /* 4 */
  text-align: start; /* 5 */
  height: 100vh; /* 6 */ // TERRATODO can we safely add 100svh?
  overflow: hidden; /* 7 */
  scroll-behavior: smooth; /* 8 */
}

/** =============== TEXT =============== */

/*
 1. This separates semantic HTML meaning and visual meaning (Terra opinionated)
 2. Ignores tags in Froala editors, can be removed once editors are unified (Terra opinionated)
 */
:where(h1, h2, h3, h4, h5, h6) {
  @include terra-typography.get-default-text-style; /* 1 */
  margin-top: 0; /* 1 */
  margin-bottom: 16px; /* 1 */
}

/**
 1. Reset margin top on paragraph (Terra opinionated)
 2. Set standard spacing (Terra opinionated)
 3. Ignores p tags in Froala editors, can be removed once editors are unified (Terra opinionated)
 */
:where(p) {
  margin-top: 0;
  margin-bottom: 16px;
}

/**
1. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
*/

:where(hr) {
  color: inherit; /* 1 */
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */

:where(nav) :where(ol, ul) {
  list-style-type: none;
  padding: 0;
}

/**
Add the correct text decoration in Chrome, Edge, and Safari.
*/

:where(abbr[title]) {
  text-decoration: underline dotted;
}

/**
 1. Add the correct font weight in Edge and Safari.
 2. Set to same weight and style regardless of parent element (Terra opinionated)
*/

:where(strong, em, i, b) {
  font-weight: 700; /* 1, 2 */
  font-style: normal; /* 2 */
}

/**
1. Improve consistency of default fonts in all browsers. (https://github.com/sindresorhus/modern-normalize/issues/3)
2. Correct the odd 'em' font sizing in all browsers.
3. Force LTR text direction
*/

:where(code, kbd, samp, pre) {
  font-family: ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace; /* 1 */
  font-size: 1em; /* 2 */
  direction: ltr; /* 3 */
  unicode-bidi: bidi-override; /* 3 */
}

/**
1. Prevent overflow of the container in all browsers (opinionated).
*/
:where(pre) {
  overflow: auto; /* 1 */
}

/**
Add the correct font size in all browsers.
*/

:where(small) {
  @include terra-typography.text(body-small);
}

/**
Prevent 'sub' and 'sup' elements from affecting the line height in all browsers.
*/

:where(sub, sup) {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

:where(sub) {
  bottom: -0.25em;
}

:where(sup) {
  top: -0.5em;
}

/*
  Resets font-styles from browser default italic (opinionated)
*/

:where(address) {
  font-style: normal;
  line-height: inherit;
}

/* =============== TABULAR DATA =============== */

/**
  1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
  2. Correct table border color inheritance in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
  3. Collapse border spacing in all browsers (opinionated).
*/

:where(table) {
  text-indent: 0; /* 1 */
  border-color: currentColor; /* 2 */
  border-collapse: collapse; /* 3 */
}

/**
  1. Sets font-weight to 700 (Terra opinionated)
  2. Matches default `<td>` alignment by inheriting `text-align`.
  3. Fix alignment for Safari
*/
:where(th) {
  font-weight: 700; /* 1 */
  text-align: inherit; /* 2 */
  text-align: -webkit-match-parent; /* 3 */
}

/**
  1. Resets the border style in all browsers (opinionated).
  */
:where(thead, tbody, tfoot, tr, td, th) {
  border-color: inherit; /* 1 */
  border-style: solid; /* 1 */
  border-width: 0; /* 1 */
}

/* =============== Forms =============== */

/**
1. Change the font styles in all browsers.
2. Remove the margin in Firefox and Safari.
*/

:where(button, input, optgroup, select, textarea) {
  font: inherit; /* 1 */
  font-size: 100%; /* 1 */
  letter-spacing: inherit; /* 1 */
  line-height: 1.5; /* 1 */
  margin: 0; /* 2 */
}

/**
  1. Remove the inheritance of text transform in Edge and Firefox.
*/

:where(button, select) {
  text-transform: none; /* 1 */
}

/**
Correct the inability to style clickable types in iOS and Safari.
*/

:where(button, [type='button' i], [type='reset' i], [type='submit' i]) {
  -webkit-appearance: button;
  appearance: button;
}

/**
Remove the inner border and padding in Firefox.
*/

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
Restore the focus styles unset by the previous rule.
*/

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
Remove the additional ':invalid' styles in Firefox.
See: https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737
*/

:-moz-ui-invalid {
  box-shadow: none;
}

/**
Add the correct vertical alignment in Chrome and Firefox.
*/

:where(progress) {
  vertical-align: baseline;
}

/**
Correct the cursor style of increment and decrement buttons in Safari.
*/

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
1. Correct the odd appearance in Chrome and Safari.
2. Correct the outline style in Safari.
TERRATODO needs more testing, the -2px fixes Safari but breaks Chrome
This is a bug and it appears to be present in TBS Reboot, Normalize.css, and Sanitize.css
This rules breaks Chrome on macOS, and with it off it breaks Safari on macOS
*/

// :where([type='search' i]) {
//   -webkit-appearance: textfield; /* 1 */
//   appearance: textfield; /* 1 */
//   outline-offset: -2px; /* 2 */
// }

/**
Remove the inner padding in Chrome and Safari on macOS.
*/

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
1. Correct the inability to style clickable types in iOS and Safari.
2. Change font properties to 'inherit' in Safari.
*/

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Change the resize direction in all browsers (opinionated).
 */

:where(textarea) {
  margin: 0; /* 1 */
  resize: vertical; /* 2 */
}

/**
 * Correct the text style of placeholders for all browsers. (Terra opinionated)
 */
::placeholder {
  color: terra-colors.color('text', 'lightest');
}

/**
 * Remove the border and padding in all browsers (opinionated).
 */

:where([type='color' i], [type='range' i]) {
  border-width: 0;
  padding: 0;
}

/* =============== Embedded Content =============== */

:where(iframe) {
  border-style: none;
}

/* =============== Interactive =============== */

/*
1. Sets base anchor styles (Terra opinionated)
*/

:where(a) {
  color: terra-colors.color(brand);
  text-decoration: none;
  cursor: pointer;
}

:where(a:hover) {
  //pseudo selector mixin used to for stories
  @include pseudo.pseudo-selector('hover') {
    text-decoration: underline;
  }
}

/*
 1. Add the correct display in Chrome and Safari.
 2. Sets to pointer to convey that elements can be clicked (opinionated)
*/

:where(summary) {
  display: list-item; /* 1 */
  cursor: pointer; /* 2 */
}

/*
 * Add the correct styles in Safari.
 */

:where(dialog) {
  background-color: terra-colors.color(white);
  border: solid;
  color: terra-colors.color(black);
  height: -moz-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: fit-content;
}

:where(dialog:not([open])) {
  display: none;
}

/* =============== REDUCED MOTION =============== */

/*
 * 1. Remove animations when motion is reduced (opinionated).
 * 2. Remove fixed background attachments when motion is reduced (opinionated).
 * 3. Remove timed scrolling behaviors when motion is reduced (opinionated).
 * 4. Remove transitions when motion is reduced (opinionated).
 */

@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    animation-delay: -1ms !important; /* 1 */
    animation-duration: 1ms !important; /* 1 */
    animation-iteration-count: 1 !important; /* 1 */
    background-attachment: initial !important; /* 2 */
    scroll-behavior: auto !important; /* 3 */
    transition-delay: 0s !important; /* 4 */
    transition-duration: 0s !important; /* 4 */
  }
}
