@use 'terra';

// Override default padding applied by Material.
// The design system calls for dividers that span the full-width of the dialog container.
.assessment-panel mat-dialog-container {
  padding-left: 0px;
  padding-right: 0px;
  overflow-x: hidden;
}

.assessment-dialog mat-dialog-container {
  .mat-mdc-dialog-surface {
    padding: 0;
  }
}

// to accomodate the new calendar icon within a form field
ninety-company-assessment-metadata-form,
ninety-reopen-company-assessment-dialog {
  .mat-datepicker-toggle {
    button {
      padding: 6px 0;
      mat-icon {
        height: 27px;
        svg {
          overflow: visible;
          vertical-align: super;
        }
      }
    }
  }
}

ninety-create-assessment-dialog,
ninety-company-assessment-detail {
  .mat-form-field-appearance-outline .mat-form-field-infix {
    // Reduce default padding applied by Material on mat-form-fields.
    padding: 9px 6px;
  }

  .mdc-checkbox__background {
    border-color: terra.color('border') !important;
    width: 20px;
    height: 20px;
    transform: translate(-1px, -1px);
  }

  .mdc-checkbox + .mdc-label {
    transform: translate(1px, 1px);
  }

  .mdc-checkbox--selected + .mdc-checkbox__ripple + .mdc-checkbox__background {
    background: terra.color('brand') !important;
  }

  .mat-input-element {
    color: terra.color('black');
  }

  .mat-input-element::placeholder,
  .mat-select-placeholder {
    // Apply consistent colors for placeholder text.
    color: terra.color('text', 'light');
    opacity: 1;
  }

  button.mat-mdc-icon-button[disabled] {
    opacity: 1;
  }
}

// Styles meant for tabs component on the Survey Tool
ninety-assessments {
  ninety-tabs-bar {
    @media screen and (max-width: 959px) {
      display: flex;
      width: 100%;
      justify-content: center;
    }

    .assessments-tabs-bar {
      @media screen and (max-width: 959px) {
        display: flex;
        justify-content: center !important;
      }

      .survey-tabs {
        &.mat-mdc-tab-header,
        &.mat-tab-header {
          .mat-mdc-tab-link-container,
          .mat-tab-link-container {
            .mat-mdc-tab-list,
            .mat-tab-list {
              .mat-mdc-tab-links,
              .mat-tab-links {
                .mat-mdc-tab-link,
                .mat-tab-link {
                  @media screen and (max-width: 959px) {
                    width: 43% !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Survey Radio Buttons MDC Styles
.mat-mdc-radio-button .mdc-form-field .mdc-radio .mdc-radio__native-control:checked + .mdc-radio__background {
  :is(.mdc-radio__outer-circle, .mdc-radio__inner-circle) {
    border-color: terra.color('brand') !important;
  }
}

ninety-company-assessments-list {
  .mat-mdc-paginator-range-actions {
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px !important;
    }
  }
  .mat-mdc-cell {
    border-color: terra.color('border', 'light');
  }
  .mat-mdc-header-cell {
    border-color: terra.color('border', 'light');
  }
}
