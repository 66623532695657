@use 'terra';

ninety-grid-skeleton {
  ninety-skeleton {
    ngx-skeleton-loader {
      span.skeleton-loader.progress {
        @include terra.radius(medium);
      }
    }
  }
}
