@use 'sass:map';
@use '../../utility/config';
@use '../../utility/typography.mixins' as typography-mixins;

//
$_default-style: 'body';
$_root-font-size: 14px;

// Fluid text
$_clamp-large-edge: 1024px; // Above this all fluid text will be the max font size
$_clamp-small-edge: 600px; // Below this all fluid text will be the min font size
@function calculateClamp($font-size-min, $font-size-max) {
  @return typography-mixins.calculateClampFromPx(
    $_root-font-size,
    $font-size-min,
    $font-size-max,
    $_clamp-small-edge,
    $_clamp-large-edge
  );
}

// To avoid having to repeat this
$_clamp-display: calculateClamp(20px, 36px);
$_clamp-headline: calculateClamp(18px, 24px);
$_clamp-title: calculateClamp(16px, 20px);
$_clamp-title-small: calculateClamp(14px, 18px);

// To avoid having to repeat this
$_font-stacks: (
  'nunito': (
    'Nunito Sans',
    sans-serif,
  ),
  'poppins': (
    'Poppins',
    sans-serif,
  ),
);

// Main list of text styles from Figma, manually created here
$_text-styles: (
  'display': (
    font-family: 'poppins',
    font-size: $_clamp-display,
    line-height: 1.5,
    font-weight: 700,
  ),
  'display-light': (
    font-family: 'poppins',
    font-size: $_clamp-display,
    line-height: 1.5,
    font-weight: 400,
  ),
  'headline': (
    font-family: 'poppins',
    font-size: $_clamp-headline,
    line-height: 1.5,
    font-weight: 600,
  ),
  'headline-light': (
    font-family: 'poppins',
    font-size: $_clamp-headline,
    line-height: 1.5,
    font-weight: 400,
  ),
  'title-bold': (
    font-family: 'poppins',
    font-size: $_clamp-title,
    line-height: 1.5,
    font-weight: 700,
  ),
  'title': (
    font-family: 'poppins',
    font-size: $_clamp-title,
    line-height: 1.5,
    font-weight: 500,
  ),
  'title-light': (
    font-family: 'poppins',
    font-size: $_clamp-title,
    line-height: 1.5,
    font-weight: 400,
  ),
  'title-small': (
    font-family: 'poppins',
    font-size: $_clamp-title-small,
    line-height: 1.5,
    font-weight: 500,
  ),
  'title-small-light': (
    font-family: 'poppins',
    font-size: $_clamp-title-small,
    line-height: 1.5,
    font-weight: 400,
  ),
  'body-large': (
    font-family: 'nunito',
    font-size: 16px,
    line-height: 1.5,
    font-weight: 400,
  ),
  'body-large-bold': (
    font-family: 'nunito',
    font-size: 16px,
    line-height: 1.5,
    font-weight: 700,
  ),
  'body': (
    font-family: 'nunito',
    font-size: $_root-font-size,
    line-height: 1.5,
    font-weight: 400,
  ),
  'body-semibold': (
    font-family: 'nunito',
    font-size: 14px,
    line-height: 1.5,
    font-weight: 600,
  ),
  'body-bold': (
    font-family: 'nunito',
    font-size: 14px,
    line-height: 1.5,
    font-weight: 700,
  ),
  'body-squashed': (
    font-family: 'nunito',
    font-size: 14px,
    line-height: 1.2,
    font-weight: 400,
  ),
  'body-squashed-bold': (
    font-family: 'nunito',
    font-size: 14px,
    line-height: 1.2,
    font-weight: 700,
  ),
  'body-small': (
    font-family: 'nunito',
    font-size: 12px,
    line-height: 1.5,
    font-weight: 400,
  ),
  'body-small-bold': (
    font-family: 'nunito',
    font-size: 12px,
    line-height: 1.5,
    font-weight: 700,
  ),
  'body-tiny': (
    font-family: 'nunito',
    font-size: 10px,
    line-height: 1.5,
    font-weight: 400,
  ),
  'body-tiny-bold': (
    font-family: 'nunito',
    font-size: 10px,
    line-height: 1.5,
    font-weight: 700,
  ),
);

///
/// Mixin to retrieve an Terra typography style
/// @param {string} $text-style - text style name from Figma
/// @param {boolean} $text-important - should output be marked as !important?  Avoid if possible
/// @output various typography rules to create the desired typography style
/// @example
/// terra.text(display);
/// terra.text(display-light);
/// terra.text('body-semibold');
///
@mixin text($text-style, $text-important: false) {
  // Convert param to string incase it's passed not as string
  $text-style: #{$text-style};
  $important: if($text-important, ' !important', '');

  @if map.has-key($_text-styles, $text-style) {
    $text-style: map.get($_text-styles, $text-style);
    @each $property-name, $property-value in $text-style {
      @if $property-name == 'font-family' {
        // Grab the font-stack so that we don't have to keep defining it on every text style
        font-family: map.get($_font-stacks, $property-value) #{$important};
      } @else {
        #{$property-name}: #{$property-value}#{$important};
      }
    }
  } @else {
    @error 'Text style does NOT exist: `#{$text-style}`';
  }
}

///
/// @output each text style as a selector
/// Meant for internal use only
/// @example
/// .ds-display {
///   ...text-styles
/// }
@mixin text-styles-as-selectors() {
  @each $style, $props in $_text-styles {
    $selector: #{config.$ds-prefix}-#{$style};

    #{$selector} {
      @include text($style);
    }
  }
}

/// Gets text style for the body (ultimate fallback for the app)
/// Meant for internal use only
/// @output default text style for the body
@mixin get-default-text-style() {
  @include text($_default-style);
}

/// Gets root font size to set on :root
/// Internal use only
/// @returns the root font size
@function get-root-text-size() {
  @return $_root-font-size;
}

///
/// Internal use only
/// @returns all the font stacks
@function font-stacks() {
  @return $_font-stacks;
}

///
/// Internal use only
/// @returns all the text styles
@function text-styles() {
  @return $_text-styles;
}

///
/// Legacy purposes only
/// This is needed only by Material and should be removed once we are off of Material
/// It's also needed by a rule for setting font-family in input,textarea,buttons.  It can be removed once we have a normalize in place
///
@function legacy-get-nunito() {
  @return map.get($_font-stacks, 'nunito');
}
