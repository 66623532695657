@use 'terra';

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

@mixin md-icon-size($size: 24px) {
  font-size: $size;
  height: $size;
  width: $size;
}

@mixin text-clamp($lines: 2) {
  overflow: hidden;
  // !important needed to overwrite the regular ellipsis mixin above, sometimes
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  text-overflow: ellipsis;
  line-height: normal;
  // !important needed to overwrite the regular ellipsis mixin above, sometimes
  white-space: normal !important;
  word-wrap: break-word;
  word-break: break-word;
}

@mixin nav-scrollbar($light-theme: true) {
  overflow-x: hidden;
  overflow-y: auto;

  // Chrome Override
  &::-webkit-scrollbar {
    display: block;
    width: 8px;
  }

  // Workaround for Scroll Shadow bleed in Light Mode
  &::-webkit-scrollbar-track-piece {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  @if $light-theme {
    // Chrome Override
    &::-webkit-scrollbar-thumb {
      background: terra.color('neutral-mid', '300');
      background-clip: padding-box;
      border: 2px solid terra.color('white');
      @include terra.radius(huge);

      &:hover {
        background: terra.color('neutral-mid', '400');
      }

      &:active {
        background: terra.color('neutral-mid', '500');
      }
    }
  } @else {
    // Chrome Override
    &::-webkit-scrollbar-thumb {
      background-color: terra.color('neutral-heavy', '200');
      background-clip: padding-box;
      border: 2px solid terra.color('neutral-heavy', '500');
      @include terra.radius(huge);

      &:hover {
        background: terra.color('neutral-heavy', '100');
      }

      &:active {
        background: terra.color('neutral-heavy', '200');
      }
    }
  }
}
