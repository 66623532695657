@use 'terra';

// material override
.mat-calendar-body-selected {
  background-color: terra.color('brand', 'light') !important;
  color: terra.color('white');
}

.cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: terra.color('brand', 'light') !important;
}

.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background-color: terra.color('brand', 'light') !important;
}
