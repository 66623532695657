@use 'terra';

#rocks {
  h2 {
    @include terra.text(body-large);
  }

  .editor-inner-wrapper {
    padding: 16px;
  }

  #rocks-archive-list-container,
  #todos-archive-list-container {
    .due-by-header {
      margin-right: 20px !important;
    }
  }

  .comments-container {
    border-top: 1px solid terra.color('border');
    padding: 16px;
    h5 {
      margin: 0 0 10px 0;
      span {
        color: terra.color('text', 'light');
        @include terra.text(body);
        margin: auto 0 auto 3px;
      }
    }

    .comment-user-container {
      margin: 8px 8px 8px 0;
      .avatar-image,
      .user-menu-initials {
        margin-top: 8px;
      }
    }

    p {
      margin: 16px 8px 8px;
      white-space: normal;
      overflow: visible;
      text-overflow: initial;
      @include terra.text(body);
    }

    editable-textarea-field {
      margin: 8px 8px 0;
      form {
        margin: 0;
      }
    }

    .comment-days-ago {
      position: relative;
      top: -15px;
      float: right;
      @include terra.text(body-small);
    }

    .delete-button {
      margin: 4px 0;
    }
  }
}
