@use 'terra';

.mat-mdc-dialog-container.mdc-dialog {
  .mat-mdc-dialog-title {
    padding: 8px 24px 24px;
    @include terra.text(title-small);
    min-width: 250px;

    // accounts for close button.
    // 1. button is 36x36
    // 84 - 36 = 48 (24xp on each side)
    margin-right: 84px;
  }

  .mat-mdc-dialog-content {
    padding: 12px 24px;
    @include terra.text(body);
    color: terra.color('text');
  }

  .mat-mdc-dialog-actions {
    padding: 20px 24px;
  }
}

.close-dialog-btn {
  position: absolute;
  top: 24px;
  right: 24px;
}
