ninety-contact-card .ninety-card-content {
  // styles for contact-card.component.scss
  .contact-complete {
    background: terra.color('green', 'shader');
    .contact-status-text {
      color: terra.color('green');
    }
    .contact-status-icon {
      color: terra.color('green');
    }
  }

  .contact-in-progress {
    background: terra.color('neutral-shader');
    .contact-status-text {
      color: terra.color('text');
    }
    .contact-status-icon {
      color: terra.color('text', 'lightest');
    }
  }

  .contact-not-started {
    background: terra.color('neutral-shader');
    .contact-status-text {
      color: terra.color('text', 'light');
    }
  }
}
