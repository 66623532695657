@use 'terra';
@import 'media-queries';

.conversation-dialog-panel div.mdc-dialog__surface {
  padding: 0;
}

// Force mat-button-wrapper styling for ninety-user-select label
ninety-create-conversation-participants-form {
  .manager-name {
    ninety-user-select {
      .create-conversation-manager-label {
        padding-left: 10px;
      }

      // Force flex alignment
      &.flex-with-label {
        width: 100%;

        .menu-btn {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;

          .mat-button-wrapper {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }
      }
    }
  }

  // Force removal of shadow (i.e. ripple)
  .remove-ripple {
    ninety-user-select {
      button {
        span[matripple] {
          content: none;
          display: none;
          visibility: hidden;
        }
      }
    }
  }
}

// Fix for conversation add user button shrinking after interaction DEV-9355
.create-conversation-section {
  ninety-user-select > ninety-button {
    display: block !important;
  }
}

.create-conversation-checkbox-row {
  padding-top: 30px;
}

.conversation-list-card .conversation-list-card-footer {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.conversation-list-card {
  ninety-card-footer {
    border: 1px solid;
    border-color: terra.color('border', 'light');
  }
  mat-row {
    min-height: 56px;
  }
  mat-cell {
    border-color: terra.color('border', 'light');
  }
  .mat-mdc-header-cell {
    border-color: terra.color('border', 'light');
  }

  @media (max-width: 740px) {
    .mat-mdc-paginator-page-size {
      display: none;
    }
    mat-row {
      min-height: 50px;
    }
  }
  @media (max-width: 599px) {
    .conversation-list-card-footer {
      flex-direction: column-reverse;
      align-items: initial;
    }
  }
}

ninety-conversations,
ninety-create-conversation-dialog,
ninety-conversation-detail,
ninety-conversation-settings {
  .mat-mdc-tab .mdc-tab-indicator__content--underline,
  .mat-mdc-tab-link .mdc-tab-indicator__content--underline,
  .mat-mdc-tab.mdc-tab--active:hover .mdc-tab-indicator__content--underline,
  .mat-mdc-tab.mdc-tab--active:focus .mdc-tab-indicator__content--underline {
    border-color: terra.color('brand') !important;
  }
}

ninety-conversation-settings {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: terra.color('white');
  }
}

ninety-create-review {
  .mat-mdc-form-field-focus-overlay {
    opacity: 0 !important;
  }
}

ninety-create-conversation-dialog {
  mat-tab-header.mat-mdc-tab-header {
    position: sticky;
    top: 0;
    z-index: 3;
    background: terra.color(white);
  }
}

ninety-formal-conversation-settings {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: terra.color('white') !important;
  }
}

// DEV-14463: Adjusts KPI *table* on discussion forms
ninety-conversation-measurables-grid ninety-measurables-grid-table div.responsive-table {
  width: max-content;
  min-width: max-content;
  max-width: max-content;
  overflow-x: auto;
  margin: 0 auto;
  clear: both;

  // NOTE: If additional styles are needed for smaller screens, put them here.
  @include lt-tablet {
    // Horizontal scroll on table when screen is small
    width: 90vw;
    min-width: 90vw;
    max-width: 90vw;
  }

  table.measurables-heat-map {
    width: max-content;
    min-width: max-content;
    max-width: max-content;
    border-collapse: collapse;

    // Helps rotated text to stay on screen
    margin-right: 18px;
    margin-left: auto;

    tbody.mdc-data-table__content tr.cdk-row {
      height: auto;

      td.cdk-cell {
        padding: 0;
        border: 0;
        margin: 0;

        // Uniform squares
        div {
          height: 20px;
          width: 20px;
          margin-right: 2px;
          margin-left: 0;
        }

        // Helps table stay on screen without horizontal scroll
        &:first-child {
          width: max-content;
          min-width: max-content;
          max-width: max-content;
          flex: 0;
          margin: 0;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          div:first-child {
            width: max-content;
            min-width: max-content;
            max-width: max-content;
            flex: 0;
            margin-right: 5px;
            margin-left: 0;
          }
        }
      }
    }

    tfoot tr.cdk-footer-row td.cdk-footer-cell {
      // Allows rotated text to be seen and not cut off
      overflow: unset;
      padding: 0;

      &.rotate {
        height: 50px;
        text-align: right;
        white-space: nowrap;
        > div {
          transform: translate(4px, -15px) rotate(-310deg);
          width: 17px;
          color: terra.color('neutral-mid', '500');
          @include terra.text(body-small);
        }
      }
    }
  }
}
