ninety-create-process {
  mat-form-field {
    div.mdc-text-field--filled {
      --mdc-filled-text-field-container-color: transparent;
    }

    div.mat-mdc-form-field-focus-overlay {
      background-color: transparent;
    }

    div.mdc-text-field {
      padding: 0;
    }

    div.mat-mdc-form-field-subscript-wrapper {
      height: 10px;
    }

    div.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
      padding-bottom: 0;
    }
  }
}
