@use 'terra';

.mat-mdc-snack-bar-container {
  &:not(.plain-text-action) {
    .mat-mdc-snack-bar-action {
      color: terra.color('text-invert', 'light');

      &:hover {
        color: terra.color('text-invert');
      }
    }
  }

  &.plain-text-action {
    .mdc-button__label {
      color: terra.color('text-invert', 'light');
    }
  }
}
