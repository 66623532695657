@use 'terra';
@import 'mixins';

$box-shadow-thickness: 4px;

// Common border styles
@mixin input-border($padding-top-bottom: 0, $padding-left-right: 16px) {
  cursor: text;

  border: none; // remove browser defaults
  outline: none; // remove browser defaults

  @include terra.text(body);
  @include terra.radius(small);

  // All variants

  &.disabled,
  &:disabled,
  &:has(input:disabled) {
    color: terra.color('text', 'disabled');
  }

  &::placeholder {
    color: terra.color('text', 'lightest');
  }

  &:not(.ninety-input--none-variant) {
    @include input-box-shadow-focus();
  }

  // Inline variant
  &.ninety-input--inline {
    background-color: transparent;

    @include input-box-shadow-error;

    // We want at least some padding to keep the text separated from the box shadow
    @include set-input-padding(6px, 1px);
  }

  // Outline variant
  &.ninety-input--outline {
    outline: 1px solid terra.color('border');
    background-color: terra.color('white');

    @include input-border-hover();
    @include input-border-focus();
    @include input-border-error();

    &.disabled,
    &:disabled,
    &:has(input:disabled) {
      background: terra.color('neutral-light', '400');
    }
  }
}

// Mixin which enforces the expected pattern to set paddings with the expected defaults
@mixin set-input-padding($padding-top-bottom: 7.5px, $padding-left-right: 16px) {
  padding: $padding-top-bottom $padding-left-right;
}

// Remove default styles from the input. Useful when you want the border styles on a parent.
@mixin strip-input-border() {
  &.ninety-input--inline,
  &.ninety-input--outline,
  &.ninety-input--error {
    outline: none;
    border: none;
    background-color: transparent;

    &:focus,
    &:hover {
      @include terra.elevation(0);
      outline: none;
      border: none;
    }
  }
}

@mixin input-border-hover {
  &:hover {
    outline: 1px solid terra.color('border', 'heavy');
  }
}

@mixin input-border-focus($thickness: 1px) {
  &:focus,
  &:has(:focus),
  &:focus-visible,
  &:has(:focus-visible) {
    outline: $thickness solid terra.color('brand');
  }
}

@mixin input-box-shadow-focus {
  &:focus,
  &:has(:focus) {
    box-shadow: 0 0 0 $box-shadow-thickness terra.color('brand', 'shader-heavy');
    @include input-box-shadow-error;
  }
}

@mixin input-box-shadow-error {
  &.ninety-input--error {
    box-shadow: 0 0 0 $box-shadow-thickness terra.color('red', 'shader-heavy');
  }
}

@mixin input-border-focus-visible {
  outline: 1px solid terra.color(brand, heavy);
}

@mixin input-border-error {
  &.ninety-input--error {
    outline: 1px solid terra.color('red');
  }
}
