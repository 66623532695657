@use 'terra';

// Global styles + Material overrides
#split-panel-form-page {
  h1 {
    @include terra.text(display);
    margin-block-start: 16px;
  }

  h2 {
    @include terra.text(headline);
    margin-block-start: 16px;
  }

  .mat-card-header-text {
    margin: 0;
  }

  mat-icon {
    width: clamp(1.5rem, 2vw, 3rem);
    height: clamp(1.5rem, 2vw, 3rem);
  }

  #add-teammates,
  .step-label-container {
    mat-icon {
      width: initial;
      height: initial;
    }
  }
}
