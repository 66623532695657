@use 'terra';
@import 'media-queries';

.uppercase {
  text-transform: uppercase;

  &-first {
    text-transform: capitalize;
  }
}

.fit-parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.snack-justify-center .mat-mdc-simple-snack-bar {
  justify-content: center;
}

.hidden {
  display: none !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.green,
.good {
  &,
  & .mat-select-value,
  & mat-icon {
    color: terra.color('green') !important;
  }
}

.yellow {
  &,
  & .mat-select-value,
  & mat-icon {
    color: terra.color('yellow') !important;
  }
}

.red,
.bad {
  &,
  & .mat-select-value,
  & mat-icon {
    color: terra.color('red') !important;
  }
}

.orange {
  &,
  & .mat-select-value,
  & mat-icon {
    color: terra.color('orange') !important;
  }
}

.blue {
  &,
  & .mat-select-value,
  & mat-icon {
    color: terra.color('ninety') !important;
  }
}

.white {
  &,
  & .mat-select-value,
  & mat-icon,
  & .muted {
    color: terra.color('white') !important;
  }
}

.muted {
  color: terra.color('text', 'light') !important;
}

.text-invert-light {
  color: terra.color('text-invert', 'light');
}

.rotate-180 {
  transform: rotate(180deg);
}

.top-space-0 {
  margin-top: 0 !important;
}

.top-space-5 {
  margin-top: 5px !important;
}

.top-space-10 {
  margin-top: 10px !important;
}

.top-space-15 {
  margin-top: 15px !important;
}

.top-space-30 {
  margin-top: 30px !important;
}

.top-space-40 {
  margin-top: 40px !important;
}

.bottom-space-0 {
  margin-bottom: 0 !important;
}

.bottom-space-5 {
  margin-bottom: 5px !important;
}

.bottom-space-10 {
  margin-bottom: 10px !important;
}

.bottom-space-20 {
  margin-bottom: 20px !important;
}

.bottom-space-15 {
  margin-bottom: 15px !important;
}

.bottom-space-30 {
  margin-bottom: 30px !important;
}

.bottom-space-75 {
  margin-bottom: 75px !important;
}

.right-space-0 {
  margin-right: 0 !important;
}

.right-space-5 {
  margin-right: 5px !important;
}

.right-space-10 {
  margin-right: 10px !important;
}

.right-space-15 {
  margin-right: 15px !important;
}

.right-space-30 {
  margin-right: 30px !important;
}

.right-space-40 {
  margin-right: 40px !important;
}

.left-space-5 {
  margin-left: 5px !important;
}

.left-space-16 {
  margin-left: 16px !important;
}

.right-space-16 {
  margin-right: 16px !important;
}

.text-cursor:hover {
  cursor: text;
}

.no-bottom-margin {
  margin-bottom: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.padding-top-18 {
  padding-top: 18px;
}

.no-border {
  border: 0 !important;
}

.w180 {
  width: 180px !important;
}

.full-width {
  width: 100% !important;
}

.width-one-third {
  width: 33.33%;
}

.width-two-thirds {
  width: 66.66%;
}

.inline-block {
  display: inline-block;
}

.hide-lt-md {
  @include lt-md() {
    display: none;
  }
}

.strike-through {
  color: terra.color('neutral-heavy', '200') !important;
  text-decoration: line-through terra.color('neutral-heavy', '100') !important;
}

.hide-mobile {
  @include lt-tablet {
    display: none !important;
  }
}

.show-mobile {
  @include gt-tablet {
    display: none !important;
  }
}

.invisible {
  visibility: hidden;
}

.icon-flipped {
  transform: scaleX(-1);
}

mat-card-footer.grid-footer {
  margin: 0;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-areas: 'add-button paginator';
  mat-paginator {
    grid-area: paginator;
    background: transparent;
  }
}

.field-wrp.compact {
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 1px 0 8px;
  }
}

.float-right {
  float: right;
}

.hide-instead-of-ngIf {
  // doing this so the components don't refresh every time they come back due to being ripped in/out of DOM
  display: none !important;
}
