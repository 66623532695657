@use 'terra';

// Component-specific styles are found at the following path:
//     /src/app/_components/inputs/quick-filter/quick-filter.component.scss

ninety-learning-action-toolbar {
  ninety-quick-filter {
    details {
      .options-list {
        min-width: 100px;
      }
    }
  }
}
