@use 'terra';

// Globally apply as default
ninety-skeleton {
  ngx-skeleton-loader {
    span.skeleton-loader.progress {
      background-color: terra.color('neutral-mid', '100');

      // Need to override our own .progress class which sets max-width to 60%
      max-width: 100%;
    }
  }
}

// TODO document in storybook
.ninety-skeleton-parent-container {
  display: flex;
  height: 100%;
}
