@use 'terra';
@import './inputs-mixins';
@import 'mixins';

.select-overlay {
  width: 100%;

  input[ninetyInput].ninety-input {
    @include strip-input-border();
    @include set-input-padding();
  }
}

[ninetyOptionList] {
  display: flex;
  flex-direction: column;
  gap: 4px;

  margin-top: 6px;
  padding: 2px 8px 8px 8px;
  max-height: 290px;
  overflow-y: auto;

  box-sizing: content-box;

  @include terra.scroll-shadow('light', terra.color('white'));
  @include nav-scrollbar(); // Force scroll-shadow into scrollbar, only works on chrome
  @include terra.radius(small);
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  [ninetyOption] {
    display: flex;
    align-items: center;
    gap: 8px;

    min-height: 36px;
    padding: 0 8px;

    cursor: pointer;

    @include terra.text(body);
    @include terra.radius(small);
    @include input-border-focus(2px);

    &:hover {
      background-color: terra.color('neutral-shader', 'light');
    }

    &[aria-selected='true'] {
      background-color: terra.color('neutral-shader');
      cursor: auto;

      &:focus {
        outline: none;
      }
    }

    &[aria-disabled='true'] {
      color: terra.color('text', 'disabled');
      cursor: auto;

      &:hover:not([aria-selected='true']) {
        background-color: transparent;
      }

      &:focus {
        outline: none;
      }
    }
  }
}
