ninety-process-card {
  .mdc-list-item {
    &__primary-text {
      display: flex;
      width: 100% !important;
      justify-content: center;
      align-items: center;
    }
  }

  .mat-mdc-card div div div.goal,
  .mat-mdc-card div div div.interval {
    transform: translateX(-14px);
  }
}
