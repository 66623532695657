@import 'media-queries';
@import './shared';

.partners-list-wrapper.detail-opened {
  @include gt-md() {
    .table-wrapper {
      max-height: calc(100vh - 390px);
    }
  }
}

.add-partner-tabs {
  .cdk-focused {
    // override the background focus on modal load per request to remove this focus style.
    background-color: transparent !important;
  }
}

.mat-tab-label.add-customer-fake-tab {
  opacity: 1;
}
