@use 'terra';

.editor-container {
  margin-right: 32px !important;
  @include terra.elevation(3);
  border-radius: terra.radius(medium) terra.radius(medium) terra.radius(medium) terra.radius(medium) !important;
  margin-bottom: 5px;
  background-color: terra.color('neutral-light', '100');
  max-width: 1200px;
  min-width: 600px;
}

.froala-toolbar {
  border-radius: terra.radius(medium) terra.radius(medium) terra.radius(none) terra.radius(none) !important;
  border: 0 !important;
}

.froala-wrapper {
  background-color: terra.color('neutral-light', '100');
  border: none !important;
  padding: 11px;
}

.fr-line-breaker {
  display: none !important;
}

.fr-view,
.fr-element {
  font-family: 'Poppins', sans-serif !important;
}

.thumb-nail {
  border-radius: terra.radius(medium) !important;
  border: 1px solid terra.color('neutral-mid', '100');
}

.thumb-nail-link {
  border-radius: terra.radius(medium) !important;
}

.thumb-nail-link-border {
  border-radius: terra.radius(medium) !important;
  border: 1px solid terra.color('neutral-mid', '100');
}

.card-thumb-nail-link {
  border-radius: terra.radius(medium) !important;
}

.card-thumb-nail-link-border {
  border-radius: terra.radius(medium) !important;
  border: 1px solid terra.color('neutral-mid', '100');
}

.menu-wrapper {
  display: flex;
  flex-direction: row;
  height: 68px !important;
  background-color: terra.color('white');
  margin-top: -73px;
  z-index: 100;
  align-items: center;
  position: absolute;

  border-radius: terra.radius(none) terra.radius(none) terra.radius(medium) terra.radius(medium);

  border-top: 1px solid terra.color('neutral-mid', '100');
  border-right: 1px solid terra.color('neutral-mid', '100');
  border-bottom: 1px solid terra.color('neutral-mid', '100');
  border-left: 1px solid terra.color('neutral-mid', '100');
  background: terra.color('neutral-light', '200');
}

.content-wrapper {
  display: flex;
  width: 100%;
  padding: 5px;
  height: 44px;
}

.file-type-icon {
  flex: 0 0 auto;
  width: 24px;
  height: 24px;
  margin-left: 18px;
}

.details-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-left: 20px;
}

.card-context-menu-wrapper {
  display: flex;
  flex-direction: row;
  height: 68px !important;
  background-color: terra.color(white);
  margin-top: -70px;
  z-index: 100;
  position: absolute;
  padding-top: 15px;
  width: 365px;
  margin-left: 1px;

  border-radius: terra.radius(none) terra.radius(none) terra.radius(medium) terra.radius(medium);
  border: 1px solid terra.color('neutral-mid', '100');
  background: terra.color('neutral-light', '200');
}

.menu-wrapper-link {
  display: flex;
  flex-direction: row;
  height: 68px !important;
  background-color: terra.color(white);
  margin-top: -75px;
  z-index: 100;
  align-items: center;
  position: absolute;

  border-radius: terra.radius(none) terra.radius(none) terra.radius(medium) terra.radius(medium);
  border: 1px solid terra.color('neutral-mid', '100');
  background: terra.color('neutral-light', '200');
}

.content-wrapper-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 23px;
  padding-right: 23px;
  height: 36px;
  cursor: pointer;
}

.details-wrapper-link {
  display: flex;
  width: 100%;
  margin-right: 10px;
  flex-direction: column;
  margin-left: 10px;
}

.file-type-icon-link {
  width: 26px;
  height: 26px;
}

.file-name {
  @include terra.text(body-small);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: pre-wrap;
}

.file-size-wrapper {
  display: flex;
  flex-direction: row;
}

.file-size {
  margin-right: 8px;
  @include terra.text(body-small);
}

.file-created-date {
  color: terra.color('text', 'light');
  @include terra.text(body-small);
}

.more-icon {
  width: 24px;
  height: 24px;
  align-self: flex-end;
  margin-left: 10px;
  margin-right: 24px;
}

.more-icon-link {
  margin-top: 6px;
  width: 25px;
}

.learn-context-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 192px !important;
  height: 132px;
  justify-content: 'center';
  align-items: 'center';
  @include terra.radius(small);
  padding: 8px 0 0 0;
  @include terra.elevation(2);
  background: terra.color('white');
}

.context-menu-item {
  display: flex;
  flex-direction: row;
  width: 176px;
  height: 36px;
  margin-bottom: 4px;
  margin-left: 8px;
  align-items: center;
  cursor: pointer;
}

.icon-container {
  width: 24px;
  height: 24px;
  box-sizing: content-box;
}

.menu-command {
  width: 113;
  height: 21px;
  margin-left: 8px;
}

.embedded-link-wrapper {
  display: inline-block;
  flex-direction: row;
  justify-content: 'space-between';
  height: 52px;
  min-width: 168px;
  max-width: 367px;
  padding: 8px 15px 8px 15px;

  border-radius: terra.radius(medium);
  border: 1px solid terra.color('neutral-mid', '100');
  background: terra.color('neutral-light', '200');
}

.embedded-link-content-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: 'flex-start';
  height: 35px;
  width: 100%;
}

.cursorPointer {
  cursor: pointer;
}

.embedded-link-content-details-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 11px;
}

.embedded-link-subcontent-details-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.embedded-link-content-page-name {
  @include terra.text(body-small-bold);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: pre-wrap;
  max-width: 168px;
}

.embedded-link-content-site-name {
  color: terra.color('text');
  @include terra.text(body-small);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: pre-wrap;
}

.embedded-link-content-domain-name {
  color: terra.color('text', 'lightest');
  @include terra.text(body-small);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: pre-wrap;
}

.vertical-line {
  width: 1px;
  height: 16px;
  background-color: terra.color('text', 'lightest');
  margin-right: 8px;
  margin-left: 8px;
}

.embedded-link-content-more-icon-link {
  margin-top: 5px !important;
  margin-left: 28px;
}

.embedded-link-content-icon {
  margin-left: 32px;
  cursor: pointer;
  height: 16px !important;
  width: 16px !important;
}

.embedded-link-delete-icon {
  margin-left: 32px !important;
  cursor: pointer;
  height: 16px !important;
  width: 16px !important;
}

.embedded-link-content-file-type-icon {
  cursor: pointer;
  width: 16px !important;
  height: 16px !important;
}

.fr-embedly {
  visibility: hidden !important;
}

.froala-last-editor {
  height: 64px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px 24px 24px 0;
  color: terra.color('text', 'light');
  background-color: #fff;
  @include terra.text('body-small');

  terra-icon {
    padding-right: 5px;
  }
  terra-avatar {
    padding-left: 8px;
  }
}

:host {
  // Correct global styles that are bleeding in to Froala
  // This ng-deep will pierce these styles downward and avoids polluting the global styles
  // Revert will revert back to the default browser styles
  ::ng-deep {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      all: revert;
    }

    p,
    i,
    em,
    strong,
    b {
      all: revert;
    }

    a {
      all: revert;
    }

    .terra-icon-froala {
      display: inline-block;
      > svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
