@use 'terra';

ninety-learning-modules-page {
  #learning-modules-page {
    .message-box {
      width: 384px;
    }
  }
}

.details-route ninety-learning-modules-page {
  #learning-modules-page {
    height: 100% !important;
  }
  height: 100% !important;
}

ninety-learning-tasks-table {
  .mat-mdc-table {
    .task-indent {
      ninety-icon svg {
        width: 56px !important;
      }
    }
  }
}

.kst-avatar-menu {
  border-radius: terra.radius(medium) !important;
  box-shadow: terra.elevation(3) !important;
}

ninety-learning-multiple-todos-dialog {
  mat-toolbar.toolbar {
    .toolbar-left {
      h2 {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
      }
    }
  }

  ninety-chip-team-select-box {
    ninety-select-clear-button {
      display: none;
      visibility: hidden;
    }
  }

  ninety-chip-team-select-box,
  ninety-chip-user-select-box {
    ninety-chip {
      .ninety-chip-ds {
        background-color: terra.color('neutral-shader', 'heavy') !important;
        color: terra.color('text') !important;
      }
    }

    &.invalid {
      ninety-chip-select-kitchen-sink {
        ninety-select-box {
          div.ninety-input--outline {
            outline: 1px solid red !important;
          }
        }
      }
    }
  }

  mat-form-field.due-date-field {
    margin: 0 !important;
    height: 36px;

    div.mat-mdc-text-field-wrapper {
      height: 36px;

      div.mat-mdc-form-field-flex {
        height: 36px;

        div.mat-mdc-form-field-infix {
          min-height: 36px;
          height: 36px;
          padding-top: 6px !important;
        }

        div.mat-mdc-form-field-icon-suffix {
          height: 36px;

          button {
            height: 36px;
            width: 36px;
            padding-top: 5px;
          }
        }
      }
    }

    &.invalid {
      div.mat-mdc-text-field-wrapper {
        outline: 1px solid red;
        border-radius: 4px;
      }
    }
  }

  .cancel {
    width: 160px !important;
  }
}

.search-results-container {
  min-width: 1200px !important;
  max-width: 1200px !important;
  max-height: calc(100vh - 200px);
  overflow-y: auto;

  @include terra.elevation(4);
  border-radius: 8px !important;

  @media screen and (max-width: 1489px) {
    min-width: 1200px !important;
  }

  @media screen and (max-width: 1460px) {
    min-width: 1000px !important;
  }

  @media screen and (max-width: 1279px) {
    min-width: 900px !important;
    left: 30px !important;
  }

  @media screen and (min-width: 1280px) {
    left: 272px !important;
  }

  @media screen and (max-width: 947px) {
    min-width: 690px !important;
  }

  @media screen and (max-width: 750px) {
    min-width: 470px !important;
  }
}

.cdk-overlay-pane .mat-mdc-menu-panel.search-results-container .mat-mdc-tab-group {
  .mat-mdc-tab-labels {
    display: flex;
    gap: 24px;
    padding-left: 24px;
  }

  .mat-mdc-tab-labels .mdc-tab {
    min-width: 65px;
    max-width: 65px;
  }

  .mdc-tab-indicator__content--underline,
  .mdc-tab:hover .mdc-tab-indicator__content--underline,
  .mdc-tab.mdc-tab--active .mdc-tab-indicator__content--underline,
  .mdc-tab:focus .mdc-tab-indicator__content--underline {
    border-top-color: terra.color('ninety');
    border-top-width: 4px;
    border-radius: terra.radius(small) terra.radius(small) 0 0;
  }
}
