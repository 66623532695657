ninety-rocks-page ninety-action-bar {
  mat-form-field {
    height: 48px;

    .mdc-text-field--filled:not(.mdc-text-field--disabled),
    .mat-mdc-form-field-focus-overlay {
      background-color: transparent;
    }

    .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix,
    .mdc-text-field {
      padding: 0;
    }

    .mdc-text-field--filled {
      .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
        .mdc-text-field__input {
          color: terra.color('text', 'light');
        }
      }
    }
  }

  .search-input button[data-cy='filters-toolbar_search-close-button'] {
    width: 24px !important;
    height: 24px !important;
    min-width: 24px !important;
    padding: 0 !important;
    border-radius: 50%;
  }

  div label.terra-switch__label {
    color: terra.color('text', 'light');
  }
}
