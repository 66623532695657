@use 'terra';

ninety-meeting-conclude {
  .mdc-list-item__primary-text {
    width: 100%;
  }

  .mdc-text-field--disabled {
    background-color: none;
    border-bottom: dotted terra.color('border', 'light');
  }
}

ninety-meetings-v2 {
  .mat-mdc-tab-nav-bar {
    background: none !important;
  }
}

.meetings-v2-card-wrp .empty-list-view {
  padding-top: 0 !important;
}

ninety-meeting:has(> ninety-meeting-rocks-issues) #issues-page-content {
  .rocks-v3-header-container {
    top: var(--mat-toolbar-standard-height);
    .ninety-page-header ninety-universal-create-btn,
    ninety-action-bar {
      display: none;
    }
  }
}

ninety-meeting:has(> ninety-rocks-page) .rocks-v3-header-container {
  border-top: 1px solid terra.color(border);
  .ninety-page-header ninety-universal-create-btn,
  ninety-action-bar {
    display: none;
  }
}
