ninety-kpi-detail {
  // Style overrides to align attachment component with the rest of the detail card
  ninety-file-attachments {
    span.terra-title-small {
      padding-left: 24px;
    }

    .list-item {
      .drag-handle {
        margin-left: -3px;
        margin-right: 3px;
      }

      .projected-content {
        margin-top: 0;
      }

      ninety-button.delete-btn {
        padding-right: 24px;
      }

      &:hover .drag-handle {
        background: transparent;
        box-shadow: none;
        color: terra.color('black');
        transform: scale(0.8);
      }
    }

    ninety-file-drag-drop {
      margin-left: 24px;
      margin-right: 24px;
      margin-bottom: 16px;
    }
  }
}
