@use 'terra';

.personal-todos-tab-card {
  .empty-list-view {
    padding-top: 20px !important;
    padding-bottom: 20px;
  }

  th {
    @include terra.text(body-small);
  }

  th:first-child:nth-last-child(3) ~ th {
    &:nth-last-child(2) {
      div {
        justify-content: center;
      }
      @media screen and (max-width: 599px) {
        display: none !important;
      }
    }
  }

  td:first-child:nth-last-child(3) ~ td {
    ninety-due-date-column ninety-inline-editable-due-date {
      @media screen and (max-width: 599px) {
        display: none !important;
      }
    }
  }

  th:first-child:nth-last-child(4) ~ th {
    &:nth-last-child(2) div {
      justify-content: space-evenly;
    }
  }

  @media screen and (max-width: 599px) {
    .hide-on-small-screen {
      display: none !important;
    }
  }

  .ninety-card-content tbody {
    ninety-due-date-column:not(:has(+ .repeat-icon)) {
      padding-right: 30px;
    }
  }
}
