// material override
ninety-pagination {
  .mat-form-field-appearance-outline .mat-form-field-infix {
    width: fit-content;
    border-top: 0;
    //padding-bottom: 9px; // Strip some default padding to match Figma
    padding-top: 11px;
    padding-bottom: 7.5px;
  }

  .mat-form-field-flex {
    margin-top: 0;
  }

  .mat-select-arrow-wrapper {
    padding-top: 1.5rem;
  }

  .mat-selector {
    height: 2rem;
  }

  .mat-form-field-wrapper {
    margin: 0;
    padding: 0;
  }

  .main-selector {
    height: 0;
  }

  /*
  overriding the default disabled style for the ninety-button component
  */
  #previous-button,
  #next-button {
    button {
      &[disabled] {
        background-color: transparent !important;
      }
    }
  }
}
