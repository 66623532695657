@use 'terra';

// See: https://getbootstrap.com/docs/4.0/utilities/display/
@media print {
  .d-print-block {
    display: block !important;
  }
  .d-print-none {
    display: none !important;
  }
  mat-toolbar {
    position: static !important;
  }
  mat-sidenav-content {
    margin-left: 0 !important;
  }
  .cdk-overlay-container {
    display: none !important;
  }
}

.p-break-inside-avoid {
  page-break-inside: avoid;
}

.PageHeader {
  prince-flow: static(header);
  @media print {
    text-align: center;
  }
}

.NinetyCopyrightFooter {
  @media screen {
    display: none;
  }
  font-size: 8pt;
  float: right;
  text-align: right;
  prince-flow: static(ninetyCopyrightFooter);
}

.NinetyLogoFooter {
  @media screen {
    display: none;
  }
  padding-left: 19px;
  position: relative;
  prince-flow: static(ninetyLogoFooter);
}

.NinetyLogoFooter::before {
  display: block;
  margin: 0;
  height: 11px;
  width: 17px;
  content: '';
  background-image: url('https://user-images.githubusercontent.com/26694930/47367949-b38fcf00-d6ae-11e8-94c5-c8942f0432cc.png');
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 5px;
  left: 0;
}

.NinetyLogoHeader {
  @media screen {
    display: none;
  }
  float: left;
  prince-flow: static(ninetyLogoHeader);
}

.NinetyTeamHeader {
  @media screen {
    display: none;
  }
  float: right;
  prince-flow: static(ninetyTeamHeader);
}

.LandscapePage {
  page: landscape;
}

@media print {
  //* hack for blank page
  body {
    border: 1px solid terra.color('white');
  }
  html {
    height: 0;
  }
}

//Feedback adjustments
.conversation-card {
  @media print {
    h2.assessment-title,
    h2.rock-completion-rate,
    h2.section-title {
      font-size: 16px !important;
      margin-bottom: 0;
    }

    h3 {
      font-size: 15px;
    }
    span {
      font-size: 14px;
    }

    .on-print {
      display: none;
    }

    .hidden {
      display: block;
      margin-bottom: -1em;
    }

    textarea {
      display: none;
    }

    .mat-form-field-infix {
      padding-bottom: 30px;
    }

    .core-value-description {
      text-align: inherit;
      display: block;
      word-break: break-all;
    }
    h3.flex2 {
      word-break: break-all;
      margin-right: 2em;
    }
  }
}

@media print {
  // Core processes, sub processes and process detail styling for pdf rendering
  .core-processes,
  .selected-core-process {
    .mat-card-title {
      font-size: 11pt !important;
    }
    h5.ranking-header {
      font-size: 9pt !important;
    }
    .process-value {
      font-size: 8pt !important;
    }
  }
  .core-processes {
    mat-card.mat-card {
      padding: 0 !important;
      margin: 0 0 10px 0 !important;
    }
  }
  .core-process-node,
  .selected-core-process {
    .mat-card,
    .sub-process-wrapper {
      width: 400px !important;
      border-bottom: 1px solid terra.color('orange') !important;
      border-radius: terra.radius(none) !important;
    }
  }
  ninety-sub-process {
    transform: scale(0.9) !important;
    margin: 0 !important;
    padding: 0 !important;
    .mat-card {
      border-bottom: 1px solid terra.color('orange') !important;
      border-radius: terra.radius(none) !important;
      width: 350px !important;
      margin: 0 !important;
      padding: 0 0 15px 0 !important;
      .checklist {
        margin: 0 !important;
        padding: 0 !important;
        li {
          margin: 0 0 0 17px !important;
          padding: 0 !important;
        }
      }
    }
    .mat-card-title {
      color: terra.color('orange') !important;
      padding-bottom: 10px !important;
    }
    .mat-card-content {
      margin-top: 5px !important;
    }
    .mat-card-subtitle {
      font-weight: bold !important;
      color: terra.color('text', 'light') !important;
      padding: 1px !important;
    }
    .mat-card-header,
    .mat-card-footer {
      border: none !important;
    }
  }
  ninety-process-detail {
    .mat-form-field-infix {
      padding-bottom: 12px !important;
    }
    textarea.mat-input-element {
      padding: 0 !important;
      margin: 0 !important;
    }
    .mat-select-arrow {
      display: none !important;
    }
  }
  .core-process-ranks {
    display: block !important;
    .middle,
    .mat-form-field {
      margin: 0 !important;
    }
  }
  div.user-div {
    .mat-card-subtitle {
      margin: 0 !important;
    }
  }
}

// Print styles for vto
@media print {
  ninety-vto {
    .vision-content {
      display: block !important;
    }
    mat-tab-group {
      display: block !important;

      .mat-tab-body-wrapper {
        display: block !important;
      }
      .mat-tab-header {
        .mat-tab-list {
          display: none !important;
        }
      }
      h4 {
        margin-left: 1em !important;
        font-size: 14px !important;
      }
      p {
        margin-left: 1em !important;
      }
      .dflex {
        margin-right: 1.25em !important;
      }
      button.ninety-edit-btn {
        display: none !important;
      }
      button.orange-on-hover {
        display: none !important;
      }
    }
  }
}
@media screen {
  .sub-detail-list {
    display: none !important;
  }
}

@media screen {
  ninety-accountability-chart {
    .accountability-chart-wrapper {
      padding: 15px;
      position: relative;
      flex: 1 1;
    }
  }
}

@media print {
  ninety-accountability-chart {
    .accountability-chart-wrapper {
      position: absolute;
      overflow: visible;
      padding: unset;
      flex: none;
      top: 0;
      left: 0;
    }
  }
}
