@use './colors';

/*
  ! DO NOT USE - FOR MAT PALETTE BACKWARDS COMPATIBILITY IN STYLES.SCSS ONLY !
  WILL BE OBSOLETE ONCE MDC MIGRATION IS COMPLETE AND MAT COMPONENT THEMES ARE NO LONGER IN USE.
*/

$legacy-company-palette: (
  50: rgba(0, 0, 0, 0),
  // transparent
  100: rgba(0, 0, 0, 0.1),
  // shadow lightest
  200: rgba(0, 0, 0, 0.2),
  // shadow heavy
  300: rgba(0, 0, 0, 0.3),
  // text lightest
  400: rgba(0, 0, 0, 0.4),
  // text lightest
  500: rgba(0, 0, 0, 0.5),
  // text light
  600: rgba(0, 0, 0, 0.6),
  // text light
  700: rgba(0, 0, 0, 0.7),
  // text
  800: rgba(0, 0, 0, 0.8),
  // text
  900: rgba(0, 0, 0, 0.9),
  // text
  A100: rgba(0, 0, 0, 0),
  A200: rgba(0, 0, 0, 0.2),
  A400: rgba(0, 0, 0, 0.4),
  A700: rgba(0, 0, 0, 0.7),
  contrast: (
    50: colors.color('text'),
    100: colors.color('text'),
    200: colors.color('text'),
    300: colors.color('text'),
    400: colors.color('text-invert'),
    500: colors.color('text-invert'),
    600: colors.color('text-invert'),
    700: colors.color('text-invert'),
    800: colors.color('text-invert'),
    900: colors.color('text-invert'),
    A100: colors.color('text'),
    A200: colors.color('text'),
    A400: colors.color('text-invert'),
    A700: colors.color('text-invert'),
  ),
);

$legacy-gray-palette: (
  25: rgb(225, 227, 228),
  // neutral mid 100
  50: rgb(198, 202, 206),
  // neutral mid 200
  100: rgb(157, 163, 171),
  // neutral mid 300
  200: rgb(126, 135, 145),
  // neutral mid 400
  300: rgb(92, 99, 108),
  // neutral mid 500
  400: rgb(78, 84, 91),
  // neutral heavy 100
  500: rgb(64, 69, 75),
  // neutral heavy 200
  600: rgb(50, 54, 58),
  // neutral heavy 300
  700: rgb(36, 39, 42),
  // neutral heavy 400
  800: rgb(22, 23, 25),
  // neutral heavy 500
  900: rgb(8, 8, 9),
  //black
  A100: rgb(198, 202, 206),
  A200: rgb(157, 163, 171),
  A400: rgb(78, 84, 91),
  A700: rgb(36, 39, 42),
  contrast: (
    50: colors.color('text'),
    100: colors.color('text'),
    200: colors.color('text'),
    300: colors.color('text'),
    400: colors.color('text-invert'),
    500: colors.color('text-invert'),
    600: colors.color('text-invert'),
    700: colors.color('text-invert'),
    800: colors.color('text-invert'),
    900: colors.color('text-invert'),
    A100: colors.color('text'),
    A200: colors.color('text'),
    A400: colors.color('text-invert'),
    A700: colors.color('text-invert'),
  ),
);
