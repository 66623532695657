@use 'terra';

// Heights of rows in the table header and body
$thead-row-height: 40px;
$tbody-row-height: 56px;

$row-border-color: terra.color('border', 'light');

@mixin colorize-icons {
  ninety-icon,
  mat-icon,
  terra-icon {
    color: terra.color('text', 'disabled');

    &:hover {
      color: terra.color('text', 'lightest');
    }
  }
}

@mixin left-row-border($border-color: terra.color('brand')) {
  td:first-of-type {
    position: relative;
  }
  td:first-of-type::before {
    content: '';
    position: absolute;
    background-color: $border-color;
    top: 0;
    left: 0;
    height: $tbody-row-height;
    width: 4px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

table.mat-mdc-table.team-headlines {
  width: 100%;
  border-collapse: collapse;

  &:not(.disable-drag-drop) tr.mat-mdc-row:hover .drag-handle {
    visibility: visible;
  }

  tr {
    -webkit-font-smoothing: auto;

    th {
      color: terra.color('text', 'light');
      @include terra.text(body-small);
    }

    th,
    td {
      border: 0;
    }

    .mat-mdc-header-cell,
    .mat-mdc-cell {
      padding-left: 16px;
      padding-right: 16px;
    }

    .mat-column-dragDrop,
    .mat-column-endSpacer {
      padding: 0;
      min-width: 24px;
    }

    .mat-column-ownedByUser {
      padding-right: 0;
    }

    .mat-column-contextMenu {
      padding-left: 6px;
      padding-right: 6px;
      @include colorize-icons;
    }

    .mat-column-title {
      padding-left: 0;
      width: 100%;
      max-width: 0;
    }

    &.mat-mdc-header-row {
      height: $thead-row-height;
    }

    &.headline-row {
      cursor: pointer;
      height: $tbody-row-height;

      .mat-mdc-tooltip-trigger {
        cursor: auto;
      }

      td {
        border-top: 1px solid $row-border-color;
      }

      &.last-headline {
        td {
          border-bottom: 1px solid $row-border-color;
          &.mat-column-dragDrop,
          &.mat-column-endSpacer {
            border-color: transparent;
          }
        }
      }

      td.mat-column-dragDrop {
        border-color: transparent;
        @include colorize-icons;
      }
      td.mat-column-endSpacer {
        border-color: transparent;
      }

      .mat-column-createdDate {
        .ago {
          display: block;
          color: terra.color('text', 'lightest');
        }
      }
    }

    &.headline-row.selected,
    &.headline-row:hover {
      td.mat-column-dragDrop,
      td.mat-column-endSpacer {
        border-top: 1px solid $row-border-color;
        border-bottom: 1px solid $row-border-color;
      }
    }

    &.headline-row:hover {
      background-color: terra.color('neutral-shader', 'lightest');
    }

    &.headline-row.selected {
      background-color: terra.color('neutral-shader', 'light');
      @include left-row-border;
    }
  }

  .drag-handle {
    visibility: hidden;
  }

  .inline-editable-title-wrp {
    min-width: 0;
    flex: 1;
  }
}
