ninety-team-todos-widget {
  .empty-list-view {
    padding-top: 40px !important;
  }
}

ninety-personal-todos-widget {
  .empty-list-view {
    padding-top: 40px !important;
  }
}
