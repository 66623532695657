@use 'terra';

// Global styles meant for Angular Material tabs component
.ninety-tabs {
  &.mat-mdc-tab-header,
  &.mat-tab-header {
    border-bottom: none;
    margin-left: -15px;

    .mat-mdc-tab-link-container,
    .mat-tab-link-container {
      .mat-mdc-tab-list,
      .mat-tab-list {
        .mat-mdc-tab-links,
        .mat-tab-links {
          .mat-mdc-tab-link,
          .mat-tab-link {
            padding: 0;
            min-width: 10px;
            margin-right: 15px;
            margin-left: 15px;

            &.with-icon {
              padding-right: 5px;
            }
          }
        }

        mat-ink-bar {
          border-radius: terra.radius(medium) terra.radius(medium) terra.radius(none) terra.radius(none);
          height: 4px;
        }

        .mdc-tab-indicator .mdc-tab-indicator__content--underline {
          border-radius: terra.radius(medium) terra.radius(medium) terra.radius(none) terra.radius(none);
          height: 4px;
          border-top-width: 4px;
          color: terra.color('brand');
          border-color: terra.color('brand');
        }
      }
    }
  }
}
