// These are global styles intended for the ninety-alert-message component.
// TODO: Remove this when create dialog can work without it
.create-conversation-section ninety-alert-message {
  display: flex;
  justify-content: center;
  align-items: center;

  .ninety-alert-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
