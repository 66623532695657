@use 'terra';
@import 'abstracts-variables';

.ninety-dialog-form {
  .field-wrp {
    margin: 16px 0;
    mat-label,
    input {
      color: terra.color('text');
      @include terra.text(body);
    }
    .short {
      width: 8rem;
    }
    .mat-hint {
      display: block;
      @include terra.text(body-small);
      &.checkbox-align {
        margin-left: 28px;
      }
    }
    &.inside {
      margin: 0 0 0 28px;
    }
  }
}

.form-fields {
  width: 100%;
  padding: 5px 0px;

  &.password {
    .visibility-icon {
      cursor: pointer;
    }
  }
}

@media screen {
  @media (min-width: 768px) {
    .form-page {
      mat-card {
        width: 412px;
        align-self: flex-start;
      }
    }
  }

  @media (min-width: 1024px) {
    .form-page {
      mat-card {
        padding: 40px;
        width: 500px;
      }
    }
  }

  @media (min-width: 1440px) {
    .form-page {
      mat-card {
        padding: 50px;
        width: 600px;
      }
    }
  }
}

#login-form,
#add-email-form,
#account-details-form,
#company-create {
  .mat-form-field-wrapper {
    padding: 0;
  }
}

.mastery-create-update-dialog {
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 5px 0 10px;
  }
}

.learning-subjects-icon-dropdown-wrp,
.mastery-icon-dropdown-wrp {
  .outline-field.mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 5px 0px 4px 0px;
    .mat-select {
      width: auto;
    }
  }
  .mat-select-arrow-wrapper {
    height: 5px;
  }
  .mat-select-value {
    min-width: 24px;
  }
  .mat-form-field-flex {
    min-height: 42px;
  }
}

.mastery-panel-class {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 30px !important;
  .mat-option {
    padding: 0;
    min-width: 42px;
    .mat-option-text {
      height: 42px;
      width: 42px;
      align-items: center;
      text-align: center;
      justify-content: center;
    }
    .mat-icon {
      margin: auto;
    }
  }
}

//detail card v2 styles for now(still on legacy)
.detail-view-card {
  mat-card-content {
    margin-bottom: 0;
    padding: 16px 24px 24px;
  }

  .gap-24 {
    display: grid;
    gap: 24px;
  }

  .gap-16 {
    display: grid;
    gap: 16px;
  }

  .form-wrp {
    mat-label {
      display: block;
      color: terra.color('text', 'light');
      line-height: 150%;
      .help-text {
        color: terra.color('text', 'lightest');
      }
    }

    .item-details_description mat-label {
      margin-bottom: 4px;
    }

    .mat-form-field {
      width: 100%;
      mat-select {
        line-height: 150%;
        .mat-select-arrow-wrapper {
          width: 16px;
          background: no-repeat url('/assets/icons/svgs/caret-down-light.svg');
          background-size: 16px 16px;
          background-position: center;
          transform: none;
        }
        .mat-select-arrow {
          opacity: 0;
        }
      }

      .mat-form-field-wrapper {
        .mat-form-field-flex {
          min-height: 36px;
          padding: 0;
        }

        padding-bottom: 0;
      }

      .mat-input-element {
        color: terra.color('text');
        line-height: 150%;
      }

      .mat-form-field-infix {
        border: 0;
        margin: 0;
        padding: 10px 16px 7px 16px;
      }

      .date-picker-icon {
        color: terra.color('text', 'lightest');
      }
    }
  }
}

.learning-subject-panel-class {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  .mat-option {
    padding: 0;
    min-width: 42px;
    .mat-option-text {
      height: 42px;
      width: 42px;
      align-items: center;
      text-align: center;
    }
    .mat-icon {
      margin: auto;
    }
  }
}

.inline-editable-due-date {
  .mat-mdc-form-field {
    .mdc-text-field--outlined {
      padding-left: 0 !important;
    }

    .mdc-notched-outline__trailing,
    .mdc-notched-outline__leading {
      border: none;
    }

    .mat-mdc-form-field-icon-suffix {
      padding-left: 0 !important;
    }
  }
  mat-datepicker-toggle {
    .mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
      display: none !important;
    }
  }

  .mat-mdc-focus-indicator,
  .mat-mdc-button-touch-target {
    display: none;
  }

  button {
    @include terra.text(body);
    width: 30px !important;
  }

  terra-icon.due-date-icon svg {
    width: 20px !important;
    height: 20px !important;
  }

  .orange {
    color: terra.color('orange') !important;
  }
}
