.tippy-content {
  padding: 8px 12px;
}

/*
  Tippy.js Theming
  Reference: https://atomiks.github.io/tippyjs/v6/themes/#creating-a-theme

  Follows this structure:
  ```html
  <div data-tippy-root="1" id="tippy-23" class="tippy-variation-tooltip">
    <div data-theme="ninety-tippy-theme" class="tippy-box">
      <div class="tippy-content">Tooltip Content</div>
      <div class="tippy-arrow"></div>
    </div>
  </div>
  ```
*/
// .tippy-box[data-theme~='ninety-tippy-theme'] {}
