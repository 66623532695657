@use 'sass:map';

$_radius-styles: (
  'none': 0px,
  'small': 4px,
  'medium': 8px,
  'large': 16px,
  'huge': 320px,
);

///
/// Mixin to retrieve a Terra Radius style
/// @param {string} $radius-style - radius style name from Figma
/// @output - border-radius value to create the desired style
/// @example
/// terra.radius(small);
/// terra.radius(medium);
/// terra.radius(large);
///
@mixin radius($radius-style) {
  border-radius: radius($radius-style);
}

///
/// Function to retrieve a Terra Radius pixel value
/// @param {string} $radius-style - radius style name from Figma
/// @output - pixel value of a border-radius to create the desired style
/// @example
/// terra.radius(small);
/// terra.radius(medium);
/// terra.radius(large);
///
@function radius($radius-style) {
  // Convert param to string for consistency
  $radius-style: #{$radius-style};
  @if map.has-key($_radius-styles, $radius-style) {
    $radius: map.get($_radius-styles, $radius-style);
    @return $radius;
  } @else {
    @error 'Radius style does NOT exist: `#{$radius-style}';
  }
}
