@use 'terra';
@import 'media-queries';

ninety-seat {
  // Win the specificity war here to stylize the ninety-user-selects add button when it is the user avatar
  .users-div-wrapper ninety-user-select.for-integrators-and-visionaries ninety-button.ninety-button > button {
    padding: 0;
  }
}

// Context Menu for SeatComponent
.seat-actions-menu ninety-button ninety-icon {
  margin-right: 16px;
}

ninety-responsibilities-detail {
  // Override the bordering (and the annoying way MDC handles it)
  mat-form-field:not(.mat-form-field-invalid) {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__trailing {
      border-color: terra.color('border') !important;
    }

    &:hover {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__trailing {
        border-color: terra.color('border', 'heavy') !important;
      }
    }
  }

  // Need to beat the specificity of the MDC styles
  .mat-mdc-select-value-text,
  input.mat-mdc-input-element {
    @include terra.text(body, true);
  }

  // Weird Froala Editor styles that crept in after this PR? Weird....
  .fr-element.fr-view {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.move-seat-panel {
  // Remove the MDC default background
  .mat-mdc-text-field-wrapper.mdc-text-field {
    background-color: transparent;
  }
}

// @deprecated - remove after LegacySeatDetailsDialogComponent is deleted
.seat-details-dialog-container-legacy {
  max-width: 756px !important;
  @media screen and (max-width: 746px) {
    width: calc(100vw - 10px) !important;
  }
  @media (max-height: 500px) {
    height: 100vh;
    padding: 20px 0;
  }
}

.seat-details-dialog-container {
  padding: 20px 0;
  height: 100vh;
  width: 100%;
  max-width: 756px !important;

  @include screen-gt(500px) {
    width: calc(100vw - 10px) !important;
    height: auto;

    ninety-seat-details-card {
      ninety-card {
        max-height: 90vh;
      }
    }
  }

  @include gt-tablet {
    max-width: 575px !important;
  }

  .mat-mdc-dialog-surface.mdc-dialog__surface {
    padding: 0;
  }
}

ninety-seat-detail {
  // Style overrides to align attachment component with the rest of the detail card
  ninety-file-attachments {
    span.terra-title-small {
      padding-left: 24px;
    }

    .list-item {
      .drag-handle {
        margin-left: -3px;
        margin-right: 3px;
      }

      .projected-content {
        margin-top: 0;
      }

      ninety-button.delete-btn {
        padding-right: 24px;
      }

      &:hover .drag-handle {
        background: transparent;
        box-shadow: none;
        color: terra.color('black');
        transform: scale(0.8);
      }
    }

    ninety-file-drag-drop {
      margin-left: 24px;
      margin-right: 24px;
      margin-bottom: 16px;
    }
  }
}
