// Turns off global Terra hover underline for anchor tags that are also Material tabs
a,
a:hover,
a:visited,
a:active {
  &.mdc-tab,
  &.mat-tab-link {
    text-decoration: none !important;
    color: inherit;
  }
}
