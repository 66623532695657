@use 'terra';
// These rules override default ninety accent colors for company branding.
// Some rules will require !important for overriding colors.
// Any rules explicitly named orange or having orange in the rule name should be changed in the future.

body {
  // Base CTA traits
  .ninety-cta-primary {
    color: terra.color('white');
    cursor: pointer;
    margin-left: 0;
    margin-right: 0;

    &[disabled] {
      cursor: initial;
    }
  }

  &.default-theme {
    .ninety-cta-primary {
      background-color: rgba(var(--secondaryAccent));
      color: terra.color('white');
      @include terra.radius(huge);

      &[disabled] {
        color: terra.color('text', 'disabled') !important;
        background-color: terra.color('border') !important;
      }
    }

    // ToDo: Move to Social Sign Up component
    #social-buttons {
      .social-btn {
        @include terra.radius(huge);
      }
    }

    // ToDo: Move to sign up stepper component
    #signup-stepper {
      $active-color: terra.color('brand');
      $completed-color: rgb(var(--secondaryAccent));
      $incompleted-color: terra.color('neutral-mid', '200');

      li {
        color: $incompleted-color;

        .step-label-icon {
          color: $incompleted-color;
        }

        .divider {
          border-color: $incompleted-color;
        }

        &.active {
          color: $active-color !important;

          .step-label-icon {
            color: $active-color !important;
          }

          .divider {
            border-color: $active-color !important;
          }
        }

        &.completed {
          color: $completed-color;

          .step-label-icon {
            color: $completed-color;
          }

          .divider {
            border-color: $completed-color;
          }
        }
      }
    }

    .ninety-inverted-branding {
      background-color: rgba(var(--secondaryAccent));
      color: terra.color('white');

      &[disabled] {
        color: terra.color('text', 'disabled') !important;
        background-color: terra.color('border') !important;
      }
    }
  }
}

.mat-toolbar.mat-accent {
  background: terra.color('brand') !important;
}

// TODO: Rename classes to be generic
.orange,
.orange .mat-select-value,
.orange mat-icon {
  color: rgb(var(--secondaryAccent)) !important;
}

.section-header {
  color: rgb(var(--secondaryAccent)) !important;
}

/* This is the "active" color showing when a tab is selected */
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background: terra.color('brand');
}

/* New tab overrides for mdc tabs */
.mat-mdc-tab-nav-bar,
.mat-tab-nav-bar {
  background-color: terra.color('white');

  .mat-mdc-tab-link {
    min-width: 160px;
  }

  /* This is the new ink bar color */
  .mdc-tab-indicator__content--underline {
    border-color: terra.color('brand') !important;
  }
}

mat-list-item.active,
[mat-list-item].active {
  .mat-list-item-content:before {
    background-color: terra.color('brand');
  }
}

mat-list-item.no-active.active,
.no-active[mat-list-item].active {
  .mat-list-item-content:before {
    background-color: unset;
  }
}

.ninety-accent-chip {
  background-color: terra.color('brand');
}

.mat-form-field-appearance-legacy {
  .mat-form-field-underline {
    background-color: rgb(var(--secondaryAccent));
  }
}

// Styling for color branding input in Settings -> Configuration
mat-form-field.color-field {
  > div.mat-form-field-wrapper {
    input {
      height: 30px;
      width: 100px;
    }
    padding-bottom: 5px;
    > div.mat-form-field-underline {
      display: none;
    }
  }
}

.mat-toolbar .mat-form-field-ripple {
  background-color: rgb(var(--secondaryAccent));
}

// TODO(mdc-migration) Remove after migration.
.mat-progress-spinner.mat-accent circle,
.mat-mdc-progress-spinner.mat-accent circle {
  stroke: terra.color('brand');
}

//Remove secondaryAccent from hover
//button:hover {
//  color: rgb(var(--secondaryAccent));
//}

.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
  background-color: terra.color('brand');
}

a[mat-button],
button[mat-mini-button] {
  &.login-link,
  &.sign-up-link,
  &.forgot {
    color: terra.color('brand');
  }
}

.branded-link-primary {
  color: terra.color('brand');
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: terra.color('brand');
}

.toDo-milestone-button:disabled {
  color: rgb(var(--secondaryAccent)) !important;
}

.toDo-milestone-button:hover {
  color: rgb(var(--secondaryAccent)) !important;
}

.branded-secondary-color {
  color: rgb(var(--secondaryAccent));
}

.branded-primary-color {
  color: terra.color('brand');
}

.branded-secondary-background {
  background: rgb(var(--secondaryAccent)) !important;
}

.branded-primary-background {
  background: terra.color('brand');
}

.mat-button.mat-accent,
.mat-icon-button.mat-accent,
.mat-stroked-button.mat-accent {
  color: terra.color('brand');
}

.create-conversation-btn,
.begin-conversation-btn,
.start-meeting-btn {
  color: terra.color('brand') !important;
}

.meeting-footer-message {
  color: rgb(var(--secondaryAccent));
}

.expand-collapse-btn {
  color: rgb(var(--secondaryAccent)) !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: terra.color('brand');
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: terra.color('brand');
}

.assessment-title {
  color: rgb(var(--secondaryAccent)) !important;
}

.section-title,
.card-titles,
.rock-completion-rate {
  color: rgb(var(--secondaryAccent)) !important;
}

.title-card {
  color: rgb(var(--secondaryAccent));
}

.orange-on-hover:hover,
.process-btn:hover {
  color: rgb(var(--secondaryAccent)) !important;
}

#rocks {
  .trending-up-icon {
    color: terra.color('brand') !important;
  }
}

.header-btns,
.header-h4s {
  button {
    &:hover:not(.mat-button-disabled) {
      color: rgb(var(--secondaryAccent)) !important;
    }
  }
}

.mat-icon.mat-accent {
  color: terra.color('brand');
}

.mat-menu-item.active,
.mat-menu-item.active mat-icon {
  color: terra.color('brand');
}

.list-item.opened::after,
.cdk-drag-preview.opened::after {
  background-color: rgb(var(--secondaryAccent));
}

// Leaving this here for now in case we need it later.
// .ninety-list {
//   mat-chip {
//     background-color: rgb(var(--secondaryAccent)) !important;
//   }
// }

button.ninety-edit-btn {
  &:hover {
    color: rgb(var(--secondaryAccent));
  }
}

.mat-select-trigger {
  border-bottom-color: rgb(var(--secondaryAccent)) !important;
}

.sidenav-link {
  &.active {
    color: rgb(var(--secondaryAccent));
  }
  &:hover {
    color: rgb(var(--secondaryAccent));
  }
}

// TODO delete after v1 is entirely deprecated in favor of src/styles/components/tree/hierarchy-tree.scss
.ac-links {
  path {
    stroke: rgb(var(--secondaryAccent));
  }
}

.meeting-section-link {
  &.active {
    color: rgb(var(--secondaryAccent)) !important;
  }
}

.meeting-controls .suspend-btn {
  color: rgb(var(--secondaryAccent));
}

.mat-progress-bar.mat-accent {
  .mat-progress-bar-background {
    fill: rgb(var(--secondaryAccent));
  }
  .mat-progress-bar-buffer {
    background-color: rgb(var(--secondaryAccent));
  }
  .mat-progress-bar-fill:after {
    background-color: terra.color('brand');
  }
}

.selected .selected-bar {
  background-color: rgb(var(--secondaryAccent));
}

.not-on-team {
  color: rgb(var(--secondaryAccent));
}

.process-highlighted {
  box-shadow: 0 3px 14px 2px rgb(var(--secondaryAccent)) !important;
}

.team-select-btn {
  border-bottom-color: rgb(var(--secondaryAccent)) !important;
}

.eos-grid {
  background-color: rgb(var(--secondaryAccent));
}

.mat-focused {
  .mat-form-field-required-marker {
    color: terra.color('brand');
  }
}

.user-name span {
  color: rgb(var(--secondaryAccent));
}

.mat-badge-accent .mat-badge-content {
  background: terra.color('brand');
}

ninety-meeting-detail-rocks-todos-issues-headlines {
  h1,
  h4,
  a {
    color: rgb(var(--secondaryAccent));
  }
}

.secondary-btn {
  color: rgb(var(--secondaryAccent)) !important;
}

.list-view-toggle {
  > .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    color: terra.color('brand');
  }
}

.custom-goal-btn {
  &:hover {
    color: rgb(var(--secondaryAccent));
  }
}

.issues-number-title {
  mat-icon {
    color: terra.color('brand');
  }
}

//TEMP solution for headlines card v2 and possible others until a main grid redesign
.detail-card-v2-wrp {
  @include terra.elevation(5);
  background-color: terra.color('white');

  z-index: 3; //fixes shadow when tabs are present, tabs have z-index of 2

  @include gt-sm() {
    padding-bottom: 0px !important;
  }
}
