@use 'terra';

ninety-rocks {
  .mdc-list-item {
    &__primary-text {
      display: flex;
      width: 100% !important;
      justify-content: center;
      align-items: center;
      @include terra.text(body);
    }
  }
}

ninety-rock-card {
  .mat-list-item.list-item {
    &:not(.no-hover):hover {
      background-color: terra.color('white') !important; // override lists.scss important
    }
  }
}
