@use 'terra';

.back-navigation > ninety-icon {
  margin-top: 0px;
  align-self: flex-start;
}

.back-navigation > span {
  margin-left: -3px;
}

.empty-state {
  width: 100%;
  svg {
    height: 78px;
  }
}

.add-button button {
  .add-button-container {
    color: terra.color('text', 'light');
  }
  &:hover {
    color: terra.color('text');
    .add-button-container {
      color: terra.color('text');
    }
  }
}

snack-bar-container {
  &.mat-mdc-snack-bar-container {
    max-width: 580px;
  }
}
