@use 'terra';

// TODO rename & establish the disco scss folder

.ninety-ag-charts-tooltip {
  background-color: terra.color(white);

  > *:not(:last-child) {
    padding-bottom: 4px;
  }
}

.ninety-disco-tooltip .tippy-box[data-theme~='light'] .tippy-content > * > *:not(:last-child) {
  padding-bottom: 4px;
}

.ninety-ag-charts-tooltip,
.ninety-disco-tooltip .tippy-box[data-theme~='light'] {
  @include terra.elevation(3);

  .tooltip-subdued-header,
  .tooltip-description {
    color: terra.color(text, light);
  }

  .tooltip-subdued-header-strong {
    @include terra.text(body-semibold);
  }

  .tooltip-label {
    @include terra.text(body-bold);
  }

  .tooltip-body {
    @include terra.text(body);
  }
}

.ninety-disco-tooltip .tippy-box[data-theme~='light'],
.ninety-ag-charts-tooltip,
.ag-chart-tooltip {
  @include terra.radius(medium);
}

.ninety-disco-tooltip .tippy-box[data-theme~='light'] .tippy-content,
.ninety-ag-charts-tooltip {
  padding: 8px 16px;

  @include terra.text(body);
}

.ag-charts-no-data-override {
  height: 100%;
  width: 100%;

  display: grid;
  align-items: center;
  justify-items: center;

  @include terra.text(body);
}
