#scheduled-meetings-card-empty {
  .empty-list-view {
    padding-top: 20px !important;
    padding-bottom: 20px;
  }
}

.schedules-context-menu {
  height: fit-content;
}
