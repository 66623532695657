@use 'terra';

.mat-mdc-select-panel.footer-paginator-select {
  mat-option.mat-mdc-option {
    @include terra.text(body-small);
  }
}

mat-paginator.mat-mdc-paginator {
  .mat-mdc-paginator-page-size-select {
    width: 84px;
  }

  .mat-mdc-paginator-range-actions button {
    padding: 0 !important;
    width: 40px !important;
  }
}
