@use 'terra';

// use ngClass to optionally turn on/off...must use component property
.table-sticky-headers {
  max-height: calc(100vh - 380px);
  overflow: auto;
  //remove height constrain if device is in portrait and less than 3 measurables are shown
  @media only screen and (orientation: landscape) and (max-height: 550px) {
    max-height: 100vh;
  }
}
.selected-bar {
  width: 4px;
  min-height: 50px;
}

.ninety-table {
  width: 100%;

  .mat-row {
    &.selected,
    &:hover {
      background-color: terra.color('neutral-mid', '100');
    }
  }

  .drag-handle {
    visibility: hidden;
    color: terra.color('neutral-mid', '300');
    cursor: move;
  }

  tr.mat-header-row {
    height: 25px;
  }

  td.mat-cell:last-of-type,
  td.mat-footer-cell:last-of-type,
  th.mat-header-cell:last-of-type {
    padding-right: 0;
  }
  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type,
  th.mat-header-cell:first-of-type {
    padding-left: 0;
  }

  .cdk-drop-list-dragging .mat-row:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  tr:hover {
    .drag-handle,
    .divider-btn {
      visibility: inherit;
    }
  }

  .cdk-drag-preview {
    padding: 0;
  }

  th.mat-header-cell {
    padding: 0 2px;
  }
}

.ninety-cell {
  border-right: 1px solid terra.color('border');
  display: flex;
  align-items: center;
  padding: 0 3px;
  min-width: 60px;
  min-height: 40px;
  justify-content: flex-end;
  box-sizing: content-box;
}

.crossed {
  background-image: linear-gradient(
      to top left,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      terra.color('neutral-mid', '200') 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(
      to top right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      terra.color('neutral-mid', '200') 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    );
  background-size: 90% 90%;
  background-repeat: no-repeat;
  background-position: center;
}

.ninety-header:not(:last-of-type) {
  border-right: 1px solid terra.color('border');
}

.td-drag-handle {
  max-width: 30px;
}

.drag-handle-th,
.drag-handle-td {
  width: 20px;
  text-align: center;
}

/// Add border styling through the use of ::after, instead of the normal CSS border.
///  $edge-gap: the padding between the edge of the table and the first/last column
@mixin include-row-border($edge-gap, $bottom: 0) {
  $total-gap: $edge-gap * 2;

  position: absolute;
  right: $edge-gap;
  left: $edge-gap;
  bottom: $bottom;

  width: calc(100% - $total-gap);
  height: 1px;

  background-color: terra.color('border', 'light');
  content: '';
}

/// Applies all default table styles
@mixin table-defaults {
  table[mat-table] {
    // General styles targeted at table - Full width, collapse borders
    @include table-base-styles();

    // Standard row styles (height, hover, etc.)
    @include table-header-row();
    @include table-row();

    // Standard left border when .selected class is applied to a row
    @include table-selectable-row();

    // Standard padding for table cells
    td,
    th {
      @include table-column-standard-padding();
    }
  }
}

/// Applies default header row font styling and sizing
@mixin table-header-row($thead-row-height: 40px) {
  tr[mat-header-row] {
    height: $thead-row-height;
    color: terra.color('text', 'light');

    // By default, when columns are set to shrink to their min content, we want to prevent header text from wrapping. Its
    // expected that tables with dynamic column titles override this. "Dynamic" in this context would not include custom language.
    white-space: nowrap;

    th[mat-header-cell] {
      @include terra.text('body');
    }
  }
}

/// Applies default row styling including hover states
@mixin table-row($tbody-row-height: 56px) {
  tr[mat-row] {
    height: $tbody-row-height;

    &:hover {
      background-color: terra.color('neutral-shader', 'lightest');
    }
  }
}

/// Adds the standard ninety row selector pattern - background color and left border on first cell
/// Expects that the class "selected" is added to a mat-row when selected
@mixin table-selectable-row($border-color: terra.color('brand'), $tbody-row-height: 56px) {
  tr[mat-row].selected {
    background-color: terra.color('neutral-shader', 'light');

    td:first-of-type {
      position: relative;
    }

    td:first-of-type::before {
      content: '';
      position: absolute;
      background-color: $border-color;
      top: 0;
      left: 0;
      height: $tbody-row-height;
      width: 4px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

@mixin table-column-of-exact-width($width) {
  width: $width;
  min-width: $width;
  max-width: $width;
}

$table-standard-right-padding: 8px;

@mixin table-column-standard-padding {
  padding-left: 16px;
  padding-right: $table-standard-right-padding;
}

// Styles table[mat-table]
@mixin table-base-styles {
  width: 100%;
  border-collapse: collapse;
}

// Creates a column that is right-aligned and has extra padding for the sort indicator. Call inside the host of your component,
// NOT inside of a .mat-column class (since thats what this mixin creates)
@mixin table-number-column($column-name) {
  table[mat-table] td[mat-cell].mat-column-#{$column-name} {
    text-align: right;

    // add extra for the sort indicator
    padding-right: $table-standard-right-padding + 18px;
  }

  // The sort indicator & header text are inside a flex container - shift them to the far right
  // Note, no change in table padding - header still has $table-standard-right-padding
  table[mat-table] th[mat-header-cell].mat-column-#{$column-name} {
    ::ng-deep {
      .mat-sort-header-container {
        justify-content: flex-end;
      }
    }
  }
}

// Causes a column to shrink to its minimum width and not grow. Often useful to combine with `white-space: nowrap;` to say
// "shrink to your min width but dont wrap" as wrapping will be the default behavior when using this mixin.
@mixin table-column-prevent-growth {
  width: 0.1%;
}
