.seat-holders-sub-menu {
  .mat-mdc-menu-item-text {
    display: flex;
    align-items: center;
  }
}

// Mat Menu Items that are also anchor tags were getting Terra global anchor styles,
a,
a:hover,
a:visited,
a:active {
  &.mat-menu-item {
    color: inherit;
    text-decoration: none;
  }
}

// Used to help in the migration to Terra icons
.mat-menu-item terra-icon:not(terra-checkbox terra-icon):not(terra-avatar terra-icon),
.mat-mdc-menu-item terra-icon:not(terra-checkbox terra-icon):not(terra-avatar terra-icon) {
  margin-right: 16px;
  vertical-align: middle;
}
