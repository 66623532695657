@use '@angular/material' as mat;
@use 'terra';

// ==============================================================================================
// Global styles for the new MDC inputs.
// ==============================================================================================

//  Outline inputs ONLY
mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline {
  //
  // This condenses the inputs to resemble what they currently look like,
  // without the need for a bunch of css overrides
  @include mat.form-field-density(-4);

  // Match color of the outline to current color
  --mdc-outlined-text-field-outline-color: #{terra.color('border')};

  // default to fill available space, but provide a class to override back to normal
  width: 100%;
  &.auto-width {
    width: auto;
  }

  // By default, the form field reserves exactly one line of space below the field for hint or error text.
  // This removes that space by default, but provides a class to override back to normal for forms, etc.
  &:not(.error-form-field) {
    .mat-mdc-form-field-bottom-align::before {
      content: unset;
    }
    margin: 0.25em 0;
  }
}

// To get the style of the MDC input, but with our overrides (no background, no left-right margin), apply
// `.legacy-no-background` to your mat-form-field.
mat-form-field.mat-mdc-form-field.legacy-no-background {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
  }

  .mat-mdc-text-field-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}

/* clears the ‘X’ from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
